import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import { Control, FieldValues, useForm } from 'react-hook-form'

import { useAnnouncement } from '../../../providers/Announcement/Announcement.provider'
import { SendNotificationRequestData } from '../../../providers/Announcement/types'
import { useOptions } from '../../../providers/Options/Options.provider'
import { Options } from '../../../providers/Options/types'
import {
  getFieldValidationSchema,
  getInputFieldBasedOnType,
} from '../../../utilities/Forms/SectionFields'
import { Field, Section, SectionFields } from '../../../utilities/Forms/types'
import { ANNOUNCEMENT_GROUP_FIELDS } from './AnnouncementFields'
import { AnnouncementsView } from './Announcements.view'

const Announcements = () => {
  const { sendNewNotification, topics } = useAnnouncement()
  const { options } = useOptions()
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(getFieldValidationSchema(ANNOUNCEMENT_GROUP_FIELDS)),
  })

  const [profileDetailFields, setProfileDetailFields] = useState<SectionFields>(
    ANNOUNCEMENT_GROUP_FIELDS
  )
  const [announcementCreated, setAnnouncementCreated] = useState<boolean>(true)
  const [announcementSubmitting, setAnnouncementSubmitting] =
    useState<boolean>(false)

  const onSubmit = async (values: FieldValues) => {
    setAnnouncementSubmitting(true)
    try {
      const Announcement: SendNotificationRequestData = {
        name: values.topicName,
        description: values.description,
      }
      await sendNewNotification(Announcement)
      setAnnouncementSubmitting(false)
      setAnnouncementCreated(false)
    } catch (err) {
      // console.error(err)
    }
  }

  const addInputFields = (
    detailFields: Field[],
    options: Options,
    errors: any,
    control: Control<FieldValues, any>
  ) =>
    detailFields.map((detailField) => ({
      ...detailField,
      editComponent: getInputFieldBasedOnType({
        field: detailField,
        options,
        errors,
        control,
      }),
    }))

  useEffect(() => {
    if (topics) {
      const formVersionOfDetails = profileDetailFields.map((section) => ({
        ...section,
        fields: addInputFields(section.fields, options, errors, control),
      }))
      setProfileDetailFields(formVersionOfDetails)
    }
  }, [topics, options, errors])

  useEffect(() => {
    profileDetailFields.forEach((section: Section) => {
      section.fields.forEach((field: Field) => {
        setValue(
          field.backendKey,
          topics ? (topics as any)[field.backendKey] : field.initialValue
        )
      })
    })
  }, [topics, options])

  return topics ? (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AnnouncementsView
          announcementDetailFields={profileDetailFields}
          user={topics}
          {...{
            announcementCreated,
            setAnnouncementCreated,
            announcementSubmitting,
            setAnnouncementSubmitting,
          }}
        />
      </form>
    </>
  ) : (
    <></>
  )
}

export { Announcements }
