import { Badge, Box, Grid, Typography } from '@mui/material'
import classNames from 'classnames'
import { classnames } from 'tss-react/tools/classnames'

import AttachmentSvg from '../../../../assets/icon.attachment.svg'
import CaretRightSvg from '../../../../assets/icon.caret-right.svg'
import { MessageData } from '../../../../providers/Messages/types'
import { ProviderUser } from '../../../../providers/ProviderDetails/types'
import { themeColors } from '../../../../theme'
import {
  dateFormatters,
  isLastMessageFromYou,
} from '../../../../utilities/HelperFunctions'
import { useStyles } from './Messaging.styles'

type MessageProps = {
  isStandAloneView?: boolean
  message: MessageData
  keyValue: string
  unread?: boolean
  openThread?: () => void
  attachmentKeys?: { id: number; key: string }[]
  displayUser?: {
    name: string
    role: string
    photoUrl: string | null
    isPatient: boolean
    onCareTeam: boolean
  }
  displayThreadUserNames?: string
  displayUserCount?: number
  myProviderDetails?: ProviderUser | null
}

const Message = ({
  isStandAloneView,
  message,
  keyValue,
  unread,
  openThread,
  attachmentKeys,
  displayUser,
  displayThreadUserNames,
  displayUserCount,
  myProviderDetails,
}: MessageProps) => {
  const { classes } = useStyles()

  const messageClick = () => {
    if (openThread) {
      openThread()
    }
  }

  const renderAvatar = () => {
    if (displayUser && displayUser.photoUrl) {
      return (
        <div
          className={classnames([
            classes.message__icon,
            classes.message__icon__image,
          ])}
          style={{
            backgroundImage: `url(${displayUser.photoUrl})`,
          }}
        ></div>
      )
    } else {
      const nameBits = displayUser
        ? displayUser.name.split(' ')
        : message.fromUser.name.split(' ')
      const initials =
        nameBits[0][0]?.toUpperCase() +
        nameBits[nameBits.length - 1][0]?.toUpperCase()

      return <div className={classes.message__icon}>{initials}</div>
    }
  }

  const renderFlags = () => {
    const flags = []

    if (message.attachments.length) {
      flags.push(<img src={AttachmentSvg} alt='attachment' key='preview' />)
    }

    flags.push(
      <img
        src={CaretRightSvg}
        alt='details'
        className={classes.message__detailsIcon}
        style={{ marginLeft: 10 }}
        key='preview-no-attachment'
      />
    )

    return <div className={classes.message__flags}>{flags}</div>
  }

  const renderHTML = (messageBody: string) => {
    if (messageBody?.replace(/<\/?[^>]+(>|$)/g, '').length > 60) {
      return `${messageBody?.replace(/<\/?[^>]+(>|$)/g, '').slice(0, 60)} ...`
    } else return messageBody?.replace(/<\/?[^>]+(>|$)/g, '')
  }

  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <Grid
        container
        direction='row'
        key={keyValue}
        onClick={messageClick}
        aria-hidden='true'
        sx={{ width: '100%' }}
      >
        <Grid item xs={2}>
          <Grid container direction='column'>
            <Grid
              item
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                minHeight: '60px',
                position: 'relative',
              }}
            >
              {unread ? <div className={classes.message__unread} /> : ''}

              {displayUserCount && displayUserCount > 1 ? (
                <Badge
                  overlap='circular'
                  badgeContent={`+${displayUserCount - 1}`}
                  // color='primary'
                  sx={{
                    '& .MuiBadge-badge': {
                      color: themeColors.white,
                      backgroundColor: themeColors.gray50,
                      width: '20px',
                      height: '20px',
                      borderRadius: '100%',
                    },
                  }}
                  className={classnames([
                    classes.message__icon,
                    classes.message__icon__image,
                  ])}
                  style={{ backgroundColor: 'transparent' }}
                >
                  {renderAvatar()}
                </Badge>
              ) : (
                renderAvatar()
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8} sx={{ width: '100%' }}>
          <Grid container direction='column'>
            <Grid container direction='row'>
              <Grid item xs={12} sx={{ width: '100%' }}>
                {/* // NCA-76: Add identifier to username to display thread is inactive due to deactivated patient. */}
                <Typography className={classes.message__providers}>
                  {displayThreadUserNames && displayThreadUserNames?.length > 80
                    ? `${displayThreadUserNames?.slice(
                        0,
                        isStandAloneView ? 35 : 70
                      )}...`
                    : // : displayThreadUserNames}
                      displayThreadUserNames}
                  {/* {console.log(displayThreadUserNames)} */}
                </Typography>
              </Grid>

              <Grid container direction='row'>
                <Grid item xs={12} sx={{ width: '100%' }}>
                  <Grid item xs={12} sx={{ width: '100%' }}>
                    <Box
                      className={classNames(
                        classes.message__isPreview,
                        isLastMessageFromYou(message, myProviderDetails!)
                          ? classes.message__isPreviewFromYou
                          : ''
                      )}
                      sx={{
                        minWidth: '100%',
                        borderColor: themeColors.gray90,
                        background: themeColors.gray90,
                        borderRadius: '0.5rem',
                      }}
                    >
                      <Typography
                        sx={{
                          minWidth: '100%',
                          marginTop: '4px',
                          fontSize: '13px',
                          padding: 1,
                          justifyContent: 'center',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: renderHTML(message.messageBody),
                        }}
                      ></Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={2} sx={{ width: '100%' }}>
          <Grid container direction='column'>
            <Grid item sx={{ width: '100%' }}>
              <Typography className={classes.message__timestamp}>
                {dateFormatters.shortenedCleanDate(message.receivedDateTime)}
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              {' '}
              {renderFlags()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Message
