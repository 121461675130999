import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme: Theme) => ({
  revealPassword: {
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  input: {
    backgroundColor: '#f5f5f5',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    border: 'none',
    padding: '0.8rem 0.5rem',
    width: '100%',
  },
  toggle: {
    width: '3em',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    background: '#f5f5f5',
    border: 'none',
    cursor: 'pointer',
  },
  icon: {
    marginTop: '0.25rem',
  },
}))
