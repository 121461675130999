import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridComparatorFn,
  GridFilterModel,
} from '@mui/x-data-grid'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useEffect, useState } from 'react'

import {
  PeerGroupList,
  PeerGroupListItem,
} from '../../../../providers/PeerGroups/types'
import { themeColors } from '../../../../theme'
import { formatPeerGroupForTable } from '../utilities'

const dayInMonthComparator: GridComparatorFn<Date> = (v1, v2) =>
  new Date(v1) < new Date(v2) ? -1 : 1

dayjs.extend(customParseFormat)
const timeOfDayComparator: GridComparatorFn<string> = (v1, v2) => {
  const startDate1Str = v1.split(' : ')[0],
    startDate2Str = v2.split(' : ')[0]

  const startDate1 = dayjs(startDate1Str, 'H:mm A'),
    startDate2 = dayjs(startDate2Str, 'H:mm A')

  return startDate1.isBefore(startDate2) ? -1 : 1
}

const COLUMNS: GridColDef[] = [
  { field: 'name', headerName: 'Name', width: 200 },
  { field: 'moderators', headerName: 'Moderators', width: 160 },
  { field: 'dayOfWeek', headerName: 'Day', width: 90 },
  {
    field: 'startTime',
    headerName: 'Time',
    width: 150,
    sortComparator: timeOfDayComparator,
  },
  { field: 'frequency', headerName: 'Frequency', width: 110 },
  { field: 'seats', headerName: 'Seats', width: 80 },
  { field: 'filledSeats', headerName: 'Filled', width: 80 },
  {
    field: 'endDate',
    headerName: 'End Date',
    width: 170,
    type: 'date',
    sortComparator: dayInMonthComparator,
  },
  {
    field: 'createdOnDate',
    headerName: 'Created Date',
    width: 170,
    type: 'date',
    sortComparator: dayInMonthComparator,
  },
  { field: 'createdBy', headerName: 'Created By', width: 160 },
].map((col) => ({ ...col, disableColumnMenu: true }))

const OPTIONS = COLUMNS.filter((col: GridColDef) =>
  ['name', 'moderators', 'dayOfWeek', 'createdBy'].includes(col.field)
).map((col: GridColDef) => col.headerName)

interface ListViewProps {
  peerGroups: PeerGroupList
  handleRowClick: (peerGroupId: number) => void
  goToNewPeerGroup: () => void
  handleJoinSessionModal: () => void
}

export const ListView = ({
  peerGroups,
  handleRowClick,
  goToNewPeerGroup,
  handleJoinSessionModal,
}: ListViewProps) => {
  const [searchField, setSearchField] = useState<string | null>('Name')
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
  const [dataColumns, setDataColumns] = useState<GridColDef[]>(COLUMNS)
  const [pageSize, setPageSize] = useState<number>(10)

  useEffect(() => {
    if (searchField && searchKeyword) {
      setFilterModel({
        items: [
          {
            columnField: dataColumns.find(
              (dc) => dc.headerName === searchField
            )!.field,
            operatorValue: 'contains',
            value: searchKeyword,
          },
        ],
      })
    } else {
      setFilterModel({ items: [] })
    }
  }, [searchField, searchKeyword])

  useEffect(() => {
    if (peerGroups) {
      const dataColumns = COLUMNS.map((col) =>
        col.field === 'name'
          ? {
              ...col,
              renderCell: (params: GridCellParams) => (
                <Box sx={{ minWidth: '100%' }}>
                  <Button
                    sx={{
                      height: '100%',
                      overflowWrap: 'wrap',
                      minWidth: '100%',
                    }}
                    variant='text'
                    onClick={() => handleRowClick(Number(params.id))}
                  >
                    <Typography
                      sx={{
                        color: themeColors.blue40,
                        fontSize: '14px',
                        maxWidth: '100%',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        textAlign: 'left',
                        minWidth: '100%',
                      }}
                    >
                      {params.value}
                    </Typography>
                  </Button>
                </Box>
              ),
            }
          : col
      )
      setDataColumns(dataColumns)
    }
  }, [peerGroups])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '10px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '125%',
          }}
        >
          Peer Groups Setup
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0 30px',
          width: '675px',
        }}
      >
        <Box sx={{ width: '225px' }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              marginBottom: '5px',
            }}
          >
            SEARCH BY
          </Typography>
          <Autocomplete
            options={OPTIONS}
            sx={{ width: 'auto' }}
            onChange={(_: any, data: any) => {
              setSearchField(data)
              setSearchKeyword(null)
            }}
            value={searchField}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size='small'
                variant='outlined'
                id='SearchByField'
                placeholder='Select field to search by'
              />
            )}
          />
        </Box>
        {searchField && (
          <Box sx={{ width: '425px' }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                marginBottom: '5px',
              }}
            >
              KEYWORD
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                size='small'
                variant='outlined'
                sx={{ height: '35px', width: '325px' }}
                onChange={(e) => setSearchKeyword(e.currentTarget.value)}
                value={searchKeyword || ''}
                placeholder={`Type here to search the table below by ${searchField.toLowerCase()}`}
              />
              <Box>
                <Button
                  variant='outlined'
                  sx={{ minWidth: '75px', marginLeft: '20px' }}
                  onClick={() => {
                    setSearchKeyword(null)
                    setSearchField(null)
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', height: '100%' }}>
        <Box sx={{ width: '100%', flexGrow: 1, height: '459px' }}>
          <DataGrid
            getRowId={(row: any) => row.peerGroupId}
            rows={peerGroups.map((pg: PeerGroupListItem) =>
              formatPeerGroupForTable(pg)
            )}
            columns={dataColumns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(v) => setPageSize(v)}
            rowHeight={70}
            filterModel={filterModel}
            disableColumnFilter={true}
            isRowSelectable={() => false}
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '100%',
              verticalAlign: 'center',
              border: 'none',
              '& .MuiDataGrid-cell:active, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:hover':
                {
                  border: 'none',
                  outline: 'none',
                },
              '& .MuiTableCell-root': {
                border: 'none',
                outline: 'none',
              },
              '& .MuiDataGrid-cell:hover': {
                pointer: 'cursor',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderTitleContainer:focus, .MuiDataGrid-columnHeaderTitleContainer:focus-within':
                {
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '100%',
                  textTransform: 'uppercase',
                  border: 'none',
                  outline: 'none',
                },
              '& .MuiDataGrid-iconButtonContainer svg': {
                color: themeColors.blue,
              },
              '& .MuiDataGrid-iconSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
                {
                  border: 'none',
                  outline: 'none',
                },
              '& .MuiDataGrid-row.even': {
                backgroundColor: themeColors.gray99,
              },
              '& .MuiDataGrid-row.even:hover, .MuiDataGrid-row.odd:hover': {
                backgroundColor: themeColors.blue90,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'name', sort: 'asc' }],
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{
              NoResultsOverlay: () => (
                <Box
                  sx={{
                    background: themeColors.white,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px',
                      color: themeColors.blue,
                    }}
                  >
                    NO RESULTS
                  </Typography>
                </Box>
              ),
            }}
          ></DataGrid>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '360px',
        }}
      >
        <Button
          variant='contained'
          sx={{ minWidth: '150px' }}
          onClick={goToNewPeerGroup}
        >
          New Peer Group
        </Button>
        <Button
          variant='outlined'
          sx={{ minWidth: '200px' }}
          onClick={handleJoinSessionModal}
        >
          Add Session to Schedule
        </Button>
      </Box>
    </Box>
  )
}
