import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import { useUser } from '../User/User.provider'
import { AccessRoleList, Provider, ProviderList } from './types'

export interface ProvidersContextInterface {
  providers: ProviderList | null
  providerAccessRoles: AccessRoleList | null
  updateProviderAccessRole: (
    providerId: number,
    accessRoleId: number
  ) => Promise<void>
}

export const ProvidersContext = createContext<ProvidersContextInterface | null>(
  null
)

export const ProvidersProvider = ({ children }: { children?: ReactNode }) => {
  const { user } = useUser()
  const { fetch } = useAxios()

  const [providers, setProviders] = useState(null)

  const [providerAccessRoles, setProviderAccessRoles] = useState(null)

  const getAllProviders = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetAllProviders',
    })

    if (data) {
      const result = data.map((p: Provider) => {
        const trimmedProvider = {
          providerId: p.providerId,
          name: p.name,
          roles: p.roles,
          accessRoles: p.accessRoles,
          isActive: p.isActive,
        }
        return trimmedProvider
      })
      setProviders(result)
    }
  }, [])

  const getProviderAccessRoles = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetProviderAccessRoles',
    })

    if (data) {
      setProviderAccessRoles(data)
    }
  }, [])

  const updateProviderAccessRole = useCallback(
    async (providerId: number, accessRoleId: number) => {
      const { error } = await fetch({
        methodType: 'POST',
        path: `Provider/UpdateProviderAccessRole?ProviderId=${providerId}&AccessRoleId=${accessRoleId}`,
      })

      await getAllProviders()

      if (error) {
        console.log('updateProviderAccessRole-error', error)
        throw new Error(`Error in updating provider access role.`)
      }
    },
    []
  )

  useEffect(() => {
    if (user?.getUsername()) {
      getAllProviders()
      getProviderAccessRoles()
    }
  }, [user?.getUsername()])

  return (
    <ProvidersContext.Provider
      value={{
        providers,
        providerAccessRoles,
        updateProviderAccessRole,
      }}
    >
      {children}
    </ProvidersContext.Provider>
  )
}

export const useProviders = () =>
  useContext(ProvidersContext) as ProvidersContextInterface
