export const awsConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    region: 'us-east-1',
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_STORAGE_S3_BUCKET,
      region: 'us-east-1',
      identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    },
  },
  Analytics: {
    disabled: false,
    autoSessionRecord: true,
    AWSPinpoint: {
      appId: process.env.REACT_APP_ANALYTICS_APP_ID,
      region: 'us-east-1',
      mandatorySignIn: false,
    },
  },
}
