import { Box, Button, TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Modal } from '../../../../components'
// import { usePatients } from '../../../../providers/Patients/Patients.provider'
import { Patient } from '../../../../providers/Patients/types' // PatientList
import { themeColors } from '../../../../theme'

// Create interface for ReactivatePatientModalProps
interface ReactivatePatientModalProps {
  setShowReactivatePatientModal: Dispatch<SetStateAction<boolean>>
  showReactivatePatientModal: boolean
  handleReactivatePatient: (
    selectedPatient: Patient
    // selectedDate: Date
    // approved: boolean
  ) => Promise<boolean>
  selectedPatient: Patient
}

export const ReactivatePatientModal = ({
  selectedPatient,
  showReactivatePatientModal,
  setShowReactivatePatientModal,
  handleReactivatePatient,
}: ReactivatePatientModalProps) => {
  // const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  // // NCA-34, default date selected is the next day from today
  // useEffect(() => {
  //   if (!selectedDate) {
  //     const today = new Date()
  //     const tomorrow = new Date(today)
  //     tomorrow.setDate(today.getDate() + 1)
  //     setSelectedDate(tomorrow)
  //   }
  // }, [selectedDate])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal
        open={showReactivatePatientModal}
        setOpen={setShowReactivatePatientModal}
        title={'REACTIVATE PATIENT'}
        width={'30%'}
        ModalContent={
          <Box sx={{ width: '92%', margin: 'auto' }}>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '19px',
                color: themeColors.grayblack,
              }}
            >
              Are you sure you want to reactivate{' '}
              <strong>{selectedPatient?.preferredName}</strong> from the
              program?
            </Typography>
            <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
              <Button
                variant='contained'
                onClick={() => setShowReactivatePatientModal(false)}
                sx={{ marginRight: '10px' }}
              >
                No
              </Button>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: themeColors.gray20,
                }}
              >
                {/* //NCA-34 changed to fit better phrasing */}
                {/* Close window and return to patient view. */}
                Do not reactivate patient.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='error'
                onClick={() => {
                  setShowReactivatePatientModal(false)
                  handleReactivatePatient(
                    selectedPatient
                    // new Date(selectedDate!)
                  )
                }}
                sx={{ marginRight: '10px' }}
              >
                Yes
              </Button>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: themeColors.gray20,
                }}
              >
                Reactivate <strong>{selectedPatient?.preferredName}</strong> to
                the program.
              </Typography>
            </Box>
          </Box>
        }
      />
    </LocalizationProvider>
  )
}
