import { patientCareTeamObj } from '../../providers/CareTeam/types'
import { ProviderInformation } from '../../providers/ProviderDetails/types'

export enum PatientOrProvider {
  Patient,
  Provider,
}

export type PopOutUser = {
  name?: string
  photoUrl?: string
  providerRole?: string
  patientMrn?: string
  patientOrProvider: PatientOrProvider
}

export interface PatientOrProviderDetailsProps {
  id?: string
  type: PatientOrProvider
  open: boolean
  handleClose: () => void
  anchorEl: any
  user: PopOutUser
  contact?: Contact
  scheduleLinks?: SchedulingLinkTabPanelProps
  providerOrPatientId?: number
  providerRole?: string
  myPopover?: boolean
  providerInformation?: ProviderInformation | null
}

export type ScheduleLink = {
  id: number
  eventTypeName: string
  scheduleLink: string
  durationInMinutes?: number
  isVisibleToPatient: boolean
}

export interface SchedulingLinkTabPanelProps {
  primaryScheduleLink?: string
  scheduleLinks?: {
    id: number
    meetingName: string
    meetingUrl: string
    duration: number
    isVisibleToPatient: boolean
  }[]
}

export interface Contact {
  email: string
  companyPhone: string
  personalPhone: string
  timeZone: string
}

export interface ContactTabPanelProps {
  contact?: Contact
}

export type gRemindersEventTypeLinks = {
  eventName: string
  eventUrl: string
  duration: number
}

export type CareTeamEntity = {
  role: string
  name: string
  primaryGRemindersLink: string
  gRemindersEventTypeLinks: gRemindersEventTypeLinks[]
}

export interface CareTeamPanelProps {
  careTeamEntities: patientCareTeamObj[]
}
