export interface Participant {
  participantName: string
  participantRole: string
  participantPhotoURL: string
  participantId: number
}

export interface Patient {
  name: string
  mrn: string
  photoURL: string
}

export enum AppointmentType {
  Zoom = 'Zoom',
  VR = 'VR',
}

export interface Appointment {
  id: number
  appointmentId: number
  name: string
  appointmentName: string
  appointmentDescription: string
  startTime: string
  endTime: string
  meetingURL: string
  participantCount: number
  participants: Participant[]
  rescheduleURL: string
  cancelURL: string
  status: string
  isVr: boolean
  isRecurring: boolean
  patients: Patient[]
  observers?: Participant[]
  earlinessThreshold?: number
  tardinessThreshold?: number
  peerGroupId?: number
  seatsAvailable?: number
}

export interface ScheduleDay {
  day: string
  appointments: Appointment[]
}

export interface Schedule {
  days: ScheduleDay[]
}

export interface DayWithAppointments {
  day: number
}

export interface PatientOrProviderInformation {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  photoUrl: string
  preferredName: string
  role?: string
  mrn?: string
}
