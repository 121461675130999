import { Box, CircularProgress } from '@mui/material'
import { useState } from 'react'

import { useOptions } from '../../../providers/Options/Options.provider'
import { usePatients } from '../../../providers/Patients/Patients.provider'
import { Patient } from '../../../providers/Patients/types'
import { useProviderDetails } from '../../../providers/ProviderDetails/ProviderDetails.provider'
import { PatientsView } from './Patients.view'
import { ViewType } from './types'

const Patients = () => {
  const {
    patients,
    deactivatePatient,
    reactivatePatient,
    updatePatientAccessRole,
    patientAccessRoles,
  } = usePatients()

  //NCA-43 Get provider email for admin identification
  const { myProviderDetails } = useProviderDetails()
  const providerEmail = myProviderDetails?.emailAddress || null

  const [viewMode, setViewMode] = useState<ViewType>(ViewType.List)
  const [peerGroupId, setPeerGroupId] = useState<number | null>(null) // NCA-34 Holding for now for future click use
  const [showDeactivatePatientModal, setShowDeactivatePatientModal] =
    useState<boolean>(false)
  const [showReactivatePatientModal, setShowReactivatePatientModal] =
    useState<boolean>(false)

  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null)

  const { optionsLoading } = useOptions()

  // NCA-41: Modal to deactivate patient.
  const handleDeactivatePatientModal = (selectedPatientID: number) => {
    const selectedPatient = patients?.find(
      (p) => p.patientId === selectedPatientID
    )
    if (selectedPatient) {
      setSelectedPatient(selectedPatient)
      setShowDeactivatePatientModal(true)
    }
  }

  // NCA-41: Modal to deactivate patient.
  const handleDeactivatePatient = async (
    selectedPatient: Patient,
    selectedDate: Date
    // approved: boolean
  ): Promise<boolean> => {
    return await deactivatePatient(selectedPatient.mrn, selectedDate)
  }

  // NCA-41: Modal to deactivate patient.
  const handleReactivatePatientModal = (selectedPatientID: number) => {
    const selectedPatient = patients?.find(
      (p) => p.patientId === selectedPatientID
    )
    if (selectedPatient) {
      setSelectedPatient(selectedPatient)
      setShowReactivatePatientModal(true)
    }
  }

  // NCA-41: Modal to deactivate patient.
  const handleReactivatePatient = async (
    selectedPatient: Patient
  ): Promise<boolean> => {
    return await reactivatePatient(selectedPatient.mrn)
  }

  const [editPatientModal, setEditPatientModal] = useState<boolean>(false)

  const handleEditPatientModal = (selectedPatientID: number) => {
    const selectedPatient = patients?.find(
      (p) => p.patientId === selectedPatientID
    )
    if (selectedPatient) {
      setSelectedPatient(selectedPatient)
      setEditPatientModal(true)
    }
  }

  const handleEditPatientAccess = async (
    selectedPatient: Patient,
    accessRoleId: number
  ): Promise<void> => {
    await updatePatientAccessRole(selectedPatient.patientId, accessRoleId)
  }

  return patients && !optionsLoading ? (
    <PatientsView
      {...{
        viewMode,
        setViewMode,
        setPeerGroupId,
        peerGroupId,
        patients,
        // NCA-41: Modal to deactivate patient
        deactivatePatient,
        showDeactivatePatientModal,
        setShowDeactivatePatientModal,
        // NCA-82: Modal to reactivate patient
        reactivatePatient,
        showReactivatePatientModal,
        setShowReactivatePatientModal,
        selectedPatient,
        //NCA-43 filter by admin
        providerEmail,
      }}
      patientAccessRoles={patientAccessRoles}
      handleDeactivatePatientModal={handleDeactivatePatientModal}
      handleDeactivatePatient={handleDeactivatePatient}
      handleReactivatePatientModal={handleReactivatePatientModal}
      handleReactivatePatient={handleReactivatePatient}
      editPatientModal={editPatientModal}
      setEditPatientModal={setEditPatientModal}
      handleEditPatientModal={handleEditPatientModal}
      handleEditPatientAccess={handleEditPatientAccess}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />{' '}
    </Box>
  )
}

export { Patients }
