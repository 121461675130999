import { useState } from 'react'

import { useAnnouncement } from '../../../../providers/Announcement/Announcement.provider'
import useInterval from '../../../../utilities/useIntervals'
import { AnnouncementView } from './Announcement.view'

const Announcement = () => {
  const { topics, getListOfTopics } = useAnnouncement()

  const [topicId, setTopicId] = useState<string | null>(null)
  const [showViewAnnouncementModal, setShowViewAnnouncementModal] =
    useState<boolean>(false)

  const handleRowClickTopicModal = (descriptionId: string) => {
    console.log(descriptionId)
    if (descriptionId) {
      setTopicId(descriptionId)
      setShowViewAnnouncementModal(true)
    }
  }

  useInterval(() => {
    getListOfTopics()
  }, 60000)

  return (
    <AnnouncementView
      {...{
        setTopicId,
        topicId,
        showViewAnnouncementModal,
        setShowViewAnnouncementModal,
      }}
      deliveredAnnouncements={topics}
      handleRowClickTopicModal={handleRowClickTopicModal}
    />
  )
}

export { Announcement }
