import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Typography,
} from '@mui/material'

import { themeColors } from '../../theme'
import { AdminSubview } from './types'

export interface AdminViewProps {
  menuItems: AdminSubview[]
  setSelectedMenuItem: React.Dispatch<React.SetStateAction<AdminSubview | null>>
  selectedMenuItem: AdminSubview | null
  setNextMenuItem: React.Dispatch<React.SetStateAction<AdminSubview | null>>
}

export const AdminView = ({
  menuItems,
  setSelectedMenuItem,
  selectedMenuItem,
  setNextMenuItem,
}: AdminViewProps) => {
  return (
    <Grid container sx={{ height: '100%' }}>
      <Grid
        item
        xs={2}
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        <Box
          sx={{
            height: '80%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            margin: 'auto',
            paddingTop: '12px',
          }}
        >
          <MenuList
            sx={{ height: '100%', borderRight: '0.5px solid lightgrey' }}
          >
            <Box>
              <Typography sx={{ fontSize: '18px', textAlign: 'center' }}>
                Admin Functions
              </Typography>
            </Box>
            <Divider sx={{ marginBottom: '20px' }} />
            {menuItems
              .filter((mi) => !mi.restricted)
              .map((menuItem: AdminSubview) => (
                <MenuItem
                  key={menuItem.title}
                  onClick={() => {
                    setSelectedMenuItem(null)
                    setNextMenuItem(menuItem)
                  }}
                  sx={{ marginBottom: '20px', fontSize: '10px' }}
                >
                  <ListItemIcon>{menuItem.Icon}</ListItemIcon>
                  <ListItemText>{menuItem.title}</ListItemText>
                </MenuItem>
              ))}
          </MenuList>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box sx={{ padding: '20px', paddingLeft: '55px' }}>
          {selectedMenuItem?.View || <CircularProgress />}
        </Box>
      </Grid>
    </Grid>
  )
}
