import { Box, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

interface MonthlyOccurrenceFieldProps {
  watchedFields: { name: string; value: any }[] | null
}

export const MonthlyOccurrenceField = ({
  watchedFields,
}: MonthlyOccurrenceFieldProps) => {
  const [startDateValue, setStartDateValue] = useState(null)
  const [frequencyValue, setFrequencyValue] = useState(null)

  useEffect(() => {
    if (watchedFields) {
      const startDateField = watchedFields.find(
        (field) => field.name === 'startDate'
      )
      const frequencyField = watchedFields.find(
        (field) => field.name === 'frequency'
      )

      if (startDateField) setStartDateValue(startDateField.value)
      if (frequencyField) setFrequencyValue(frequencyField.value)
    }
  }, [watchedFields])

  const dayNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const calculateDayOfWeekOccurrence = (startDate: Date): string => {
    const firstDayOfMonth = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      1
    )
    let occurrence = 1

    for (
      let currentDay = new Date(firstDayOfMonth);
      currentDay.getMonth() === startDate.getMonth();
      currentDay.setDate(currentDay.getDate() + 1)
    ) {
      if (currentDay.getDay() === startDate.getDay()) {
        if (currentDay.getDate() === startDate.getDate()) {
          break
        }
        occurrence++
      }
    }

    const ordinalSuffix =
      occurrence === 1
        ? 'st'
        : occurrence === 2
        ? 'nd'
        : occurrence === 3
        ? 'rd'
        : 'th'

    if (occurrence === 5) {
      return 'Last'
    }

    return occurrence + ordinalSuffix
  }

  return (
    <>
      {watchedFields && startDateValue !== undefined && frequencyValue === 3 && (
        <Box sx={{ width: '30%' }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              marginBottom: '5px',
            }}
          >
            Monthly Occurrence
          </Typography>
          <Box>
            <TextField
              size='small'
              variant='filled'
              disabled
              sx={{
                height: '100%',
                width: '100%',
              }}
              value={
                startDateValue
                  ? `${calculateDayOfWeekOccurrence(
                      new Date(startDateValue)
                    )} ${
                      dayNames[new Date(startDateValue).getDay()]
                    } of Every Month`
                  : undefined
              }
            />
          </Box>
        </Box>
      )}
    </>
  )
}
