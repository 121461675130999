import { Box, Grid, Typography } from '@mui/material'

import AttachmentSvg from '../../../../assets/icon.attachment.svg'
import RedFlag from '../../../../assets/icon.flag.svg'
import { MessageData } from '../../../../providers/Messages/types'
import { ProviderUser } from '../../../../providers/ProviderDetails/types'
import { themeColors } from '../../../../theme'
import { dateFormatters } from '../../../../utilities/HelperFunctions'
import { useStyles } from './Messaging.styles'

type MessageProps = {
  isStandAloneView?: boolean
  message: MessageData
  keyValue: string
  unread?: boolean
  openThread?: () => void
  attachmentKeys?: { id: number; key: string }[]
  displayUser?: {
    name: string
    role: string
    photoUrl: string | null
    isPatient: boolean
    onCareTeam: boolean
  }
  displayThreadUserNames?: string
  displayUserCount?: number
  myProviderDetails?: ProviderUser | null
}

const MobileMessage = ({
  message,
  keyValue,
  unread,
  openThread,
  attachmentKeys,
}: MessageProps) => {
  const { classes } = useStyles()

  const messageClick = () => {
    if (openThread) {
      openThread()
    }
  }

  const renderFlags = () => {
    const flags = []
    if (message.urgent) {
      flags.push(
        <div key='urgent' className={classes.message__urgent}>
          <span>High Priority &nbsp;</span>
          <img
            src={RedFlag}
            alt='flag'
            style={{ marginLeft: 10 }}
            key='not-preview'
          />
        </div>
      )
    }

    return <div className={classes.message__flags}>{flags}</div>
  }

  const renderAttachments = () => {
    return attachmentKeys?.map((k: { id: number; key: string }, i: number) => {
      if (k.id === message.messageId) {
        return (
          <div
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100px',
            }}
            className={classes.msgThread__attachment}
          >
            <img
              src={AttachmentSvg}
              alt={'attachment'}
              style={{
                marginRight: 8,
              }}
            />
            <a href={k.key}>{k.key.split('?')[0].slice(-8)}</a>
          </div>
        )
      } else return null
    })
  }

  const renderHTML = (messageBody: string) => {
    return messageBody
  }

  return (
    <Grid item xs={12} sx={{ width: '100%' }}>
      <Grid
        container
        direction='column'
        justifyContent='flex-start'
        key={keyValue}
        onClick={messageClick}
        aria-hidden='true'
        sx={{ width: '100%' }}
      >
        <Grid item xs={2}>
          <Grid container direction='column'>
            <Grid
              item
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              {unread ? <div className={classes.message__unread} /> : ''}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8} sx={{ width: '100%' }}>
          <Grid container direction='column'>
            <Grid
              container
              direction='column'
              alignItems={'flex-start'}
              marginLeft={message.fromUser.isPatient ? 'none' : '30%'}
            >
              <Typography className={classes.message__timestamp}>
                {message.fromUser.name}
              </Typography>

              <Box
                sx={{
                  width: '70%',
                  height: 'max-content',
                  padding: '10px',
                  borderRadius: '10px',
                  bgcolor: message.fromUser.isPatient
                    ? themeColors.gray90
                    : themeColors.blue80,
                }}
              >
                {' '}
                <Typography
                  style={{
                    fontSize: '14px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: renderHTML(message.messageBody),
                  }}
                ></Typography>
              </Box>
              <Typography className={classes.message__timestamp}>
                {dateFormatters.prettyDateWithTime(message.receivedDateTime)}
              </Typography>
              <Grid item xs={4}>
                {' '}
                {renderFlags()}
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  width: '100%',
                  marginTop: 0,
                  marginBottom: '10px',
                }}
              >
                {renderAttachments()}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MobileMessage
