import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import * as yup from 'yup'

import {
  FieldType,
  OptionsSelector,
  SectionFields,
} from '../../../utilities/Forms/types'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

export const PEER_GROUP_FIELDS: SectionFields = [
  {
    hasInstructions: true,
    fields: [
      {
        label: 'Group Name',
        backendKey: 'groupName',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        validation: yup.string().required('Group name is required'),
        watch: true,
      },
      {
        label: 'Moderator(s)',
        backendKey: 'moderators',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        optionsSelector: OptionsSelector.ProviderUsers,
        fieldType: FieldType.Multiselect,
        minWidthPercent: 30,
        validation: yup
          .array()
          .of(yup.mixed())
          .required('At least one moderator is required'),
      },
      {
        label: 'Meeting Category',
        backendKey: 'categories',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        optionsSelector: OptionsSelector.MeetingCategories,
        fieldType: FieldType.Multiselect,
        minWidthPercent: 50,
        validation: yup
          .array()
          .of(yup.mixed())
          .required('At least one category is required'),
      },
    ],
  },
  {
    id: 'isRecurringMeeting',
    fields: [
      {
        label: 'Recurring Meeting?',
        backendKey: 'isRecurringMeeting',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        options: [
          {
            label: 'No',
            value: false,
          },
          {
            label: 'Yes',
            value: true,
          },
        ],
        fieldType: FieldType.Radio,
        watch: true,
        validation: yup
          .bool()
          .required(
            'Additional fields based on whether or not the meeting is reoccurring are required'
          ),
        initialValue: true,
      },
    ],
  },
  {
    id: 'recurringMeetingDetails',
    hideByDefault: true,
    dependentOn: { key: 'isRecurringMeeting', being: true },
    fields: [
      {
        label: 'Start Date',
        backendKey: 'startDate',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        fieldType: FieldType.Date,
        watch: true,
        validation: yup
          .date()
          .typeError('Start date is required')
          .required('Start date is required')
          .nullable()
          .default(undefined),
      },
      {
        label: 'End Date',
        backendKey: 'endDate',
        isPatientVisible: false,
        required: true,
        isEditable: true,
        fieldType: FieldType.Date,
        validation: yup.mixed().when('isRecurringMeeting', {
          is: true,
          then: yup
            .date()
            .typeError('End date is required')
            .required('End date is required')
            .test(
              'Is date greater',
              'End date cannot be in the past',
              (value) => dayjs().isSameOrBefore(value, 'day')
            )
            .test(
              'Is after start date',
              'End date cannot be before start date',
              function (value) {
                return dayjs(this.parent['startDate']).isSameOrBefore(
                  value,
                  'day'
                )
              }
            )
            .nullable()
            .default(undefined),
          otherwise: yup.date().nullable().default(undefined),
        }),
      },
      {
        label: 'Start Time',
        backendKey: 'startTime',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        fieldType: FieldType.Time,
        validation: yup
          .date()
          .typeError('Start time is required')
          .required('Start time is required')
          .nullable()
          .default(undefined),
      },
      {
        label: 'End Time',
        backendKey: 'endTime',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        fieldType: FieldType.Time,
        validation: yup
          .date()
          .typeError('End time is required')
          .required('End time is required')
          .test(
            'Is after start time - reoccurring',
            'End time cannot be before start time',
            function (value) {
              const [
                isolatedStartTimeHours,
                isolatedStartTimeMinutes,
                isolatedStartTimeSeconds,
              ] = [
                dayjs(this.parent['startTime']).hour(),
                dayjs(this.parent['startTime']).minute(),
                dayjs(this.parent['startTime']).second(),
              ]
              const [
                isolatedEndTimeHours,
                isolatedEndTimeMinutes,
                isolatedEndTimeSeconds,
              ] = [
                dayjs(value).hour(),
                dayjs(value).minute(),
                dayjs(value).second(),
              ]

              const startTime = dayjs()
                .hour(isolatedStartTimeHours)
                .minute(isolatedStartTimeMinutes)
                .second(isolatedStartTimeSeconds)
              const endTime = dayjs()
                .hour(isolatedEndTimeHours)
                .minute(isolatedEndTimeMinutes)
                .second(isolatedEndTimeSeconds)
              return startTime.isBefore(endTime)
            }
          )
          .nullable()
          .default(undefined),
      },
      {
        label: 'Frequency',
        backendKey: 'frequency',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        optionsSelector: OptionsSelector.ScheduleFrequencies,
        fieldType: FieldType.Autocomplete,
        minWidthPercent: 30,
        initialValue: 1,
        watch: true,
        validation: yup.mixed().when('isRecurringMeeting', {
          is: true,
          then: yup.mixed().required('Frequency is required'),
          otherwise: yup.mixed().nullable(),
        }),
      },
    ],
  },
  {
    id: 'oneTimeMeetingDetails',
    dependentOn: { key: 'isRecurringMeeting', being: false },
    fields: [
      {
        label: 'Date',
        backendKey: 'startDate',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        fieldType: FieldType.Date,
        validation: yup
          .date()
          .typeError('Start date is required')
          .required('Start date is required')
          .nullable()
          .default(undefined),
      },
      {
        label: 'Start Time',
        backendKey: 'startTime',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        fieldType: FieldType.Time,
        validation: yup
          .date()
          .typeError('Start time is required')
          .required('Start time is required')
          .nullable()
          .default(undefined),
      },
      {
        label: 'End Time',
        backendKey: 'endTime',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        fieldType: FieldType.Time,
        validation: yup
          .date()
          .typeError('End time is required')
          .required('End time is required')
          .test(
            'Is after start time - single',
            'End time cannot be before start time',
            function (value) {
              const [
                isolatedStartTimeHours,
                isolatedStartTimeMinutes,
                isolatedStartTimeSeconds,
              ] = [
                dayjs(this.parent['startTime']).hour(),
                dayjs(this.parent['startTime']).minute(),
                dayjs(this.parent['startTime']).second(),
              ]
              const [
                isolatedEndTimeHours,
                isolatedEndTimeMinutes,
                isolatedEndTimeSeconds,
              ] = [
                dayjs(value).hour(),
                dayjs(value).minute(),
                dayjs(value).second(),
              ]

              const startTime = dayjs()
                .hour(isolatedStartTimeHours)
                .minute(isolatedStartTimeMinutes)
                .second(isolatedStartTimeSeconds)
              const endTime = dayjs()
                .hour(isolatedEndTimeHours)
                .minute(isolatedEndTimeMinutes)
                .second(isolatedEndTimeSeconds)
              return startTime.isBefore(endTime)
            }
          )
          .nullable()
          .default(undefined),
      },
    ],
  },
  {
    id: 'patientJoinInfoAndObservers',
    fields: [
      {
        label: '# Patient Seats',
        backendKey: 'seats',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        fieldType: FieldType.Number,
        initialValue: 5,
        maxValue: 15,
        validation: yup
          .number()
          .typeError('# of patient seats is required')
          .required('# of patient seats is required'),
      },
      {
        label: 'When can patients join the session?',
        backendKey: 'minutesBefore,minutesAfter',
        subLabels: ['minutes before', 'minutes after'],
        isPatientVisible: false,
        required: true,
        isEditable: true,
        options: [
          {
            label: '5',
            value: '5',
          },
          {
            label: '10',
            value: '10',
          },
          {
            label: '15',
            value: '15',
          },
          {
            label: '30',
            value: '30',
          },
        ],
        fieldType: FieldType.QuestionGroup,
        minWidthPercent: 30,
        initialValue: ['10', '10'],
      },
      {
        label: 'Observers',
        backendKey: 'observers',
        isPatientVisible: false,
        required: false,
        isEditable: true,
        initialValue: [],
        optionsSelector: OptionsSelector.ProviderUsers,
        fieldType: FieldType.Multiselect,
        minWidthPercent: 30,
        validation: yup.array().of(yup.mixed()).nullable(),
      },
    ],
  },
  {
    id: 'descriptionAndMedia',
    fields: [
      {
        label: 'Description',
        backendKey: 'description',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        minWidthPercent: 40,
        minWidth: 400,
        fieldType: FieldType.TextArea,
        validation: yup.string().required('Description is required'),
      },
      {
        label: 'Media',
        backendKey: 'media',
        isPatientVisible: true,
        required: false,
        isEditable: true,
        minWidthPercent: 60,
        minWidth: 600,
        fieldType: FieldType.Media,
        validation: yup.array().of(yup.mixed()).nullable(),
      },
    ],
  },
  {
    id: 'backgroundDesigner',
    fields: [
      {
        label: 'Thumbnail Image',
        backendKey: 'backgroundImageId',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        observe: 'groupName',
        optionsSelector: OptionsSelector.PeerGroupBackgrounds,
        fieldType: FieldType.ImageSelector,
        minWidthPercent: 100,
        validation: yup.string().nullable(),
      },
    ],
  },
]
