// import { startCase, toLower } from 'lodash'

import { Patient } from '../../../providers/Patients/types'
// import { dateFormatters } from '../../../utilities/HelperFunctions'
import { PatientTableRow } from './types'

// NEW PATIENTS FORMAT
// NCA-43: Format 'Patients' table
export const formatPatientsForTable = (
  apiPatients: Patient
): PatientTableRow => ({
  ...apiPatients,
  patientId: apiPatients.patientId,
  preferredName: apiPatients.preferredName,
  mrn: apiPatients.mrn,
  // isActive: apiPatients.isActive,
  deactivationDate: apiPatients.deactivationDate!,
  accessRoles: apiPatients.accessRoles[0].description,
})
