import { Box, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'

import { Modal } from '../../../../components'
// import { themeColors } from '../../../../theme'

interface ViewAnnouncementModalProps {
  showViewAnnouncementModal: boolean
  setShowViewAnnouncementModal: Dispatch<SetStateAction<boolean>>
  description: string | null
}

export const ViewAnnouncementModal = ({
  showViewAnnouncementModal,
  setShowViewAnnouncementModal,
  description,
}: ViewAnnouncementModalProps) => {
  return (
    <Modal
      open={showViewAnnouncementModal && !!description}
      setOpen={setShowViewAnnouncementModal}
      title={'ANNOUNCEMENT DESCRIPTION'}
      width={'30%'}
      ModalContent={
        <Box sx={{ margin: '25px' }}>
          <Typography
            sx={{ fontSize: '16px', lineHeight: '19px', paddingBottom: '20px' }}
          >
            {description}
          </Typography>
        </Box>
      }
    />
  )
}
