import { Grid } from '@mui/material'

import { Widget } from '../../components'
import { Widget as WidgetType } from './Widgets/types'

interface DashboardViewProps {
  widgets: WidgetType[]
  seedParams: {
    messageThreadId: string | null
  }
}

export const DashboardView = ({ widgets }: DashboardViewProps) => {
  return (
    <Grid
      container
      sx={{ height: { sm: `fit-content`, md: `100%` }, minWidth: '100%' }}
      spacing={2}
      flexDirection='column'
    >
      {widgets.map((widget) => {
        const { Provider, Buttons } = widget

        return (
          <Grid
            key={widget.name}
            item
            xs={12}
            sm={12}
            md={widget.width === 'half' ? 6 : 12}
            lg={widget.width === 'half' ? 6 : 12}
            xl={widget.width === 'half' ? 6 : 12}
            sx={{
              minHeight: widget.height === 'half' ? '50%' : '100%',
            }}
          >
            <Provider>
              <Widget
                title={widget.name}
                Buttons={Buttons}
                usePadding={widget.usePadding}
              >
                <>{widget.element}</>
              </Widget>
            </Provider>
          </Grid>
        )
      })}
    </Grid>
  )
}
