import {
  AccountCircle,
  Assignment,
  EventNote,
  ExitToApp,
  Forum,
  Home,
  RateReview,
  Reply,
  SupervisorAccount,
  Tune,
} from '@mui/icons-material'
import { createContext, ReactNode, useContext } from 'react'

import { Admin } from '../../views/Admin/Admin.container'
import { Dashboard } from '../../views/Dashboard/Dashboard.container'
import Messaging from '../../views/Dashboard/Widgets/Messaging/Messaging.container'
import { Profile } from '../../views/Profile/Profile.container'
import { Schedule } from '../../views/Schedule/Schedule.container'
import { MessagesProvider } from '../Messages/Messages.provider'
import { VrHeadsetProvider } from '../VrHeadset/VrHeadset.provider'
import { Logout } from './../../views/Auth/Auth.container'
import { NavigationContextType, NavLink } from './types'

const naviagtionLinks: NavLink[] = [
  {
    Icon: <Home />,
    title: 'Home',
    route: 'home',
    View: <Dashboard />,
    useViewPaper: false,
  },
  {
    Icon: <EventNote />,
    title: 'Schedule',
    route: 'schedule',
    View: <Schedule />,
    restricted: true,
  },
  {
    Icon: <RateReview />,
    title: 'Messages',
    route: 'messages',
    restricted: true,
  },
  {
    Icon: <Assignment />,
    title: 'Tasks',
    route: 'tasks',
    restricted: true,
  },
  {
    Icon: <SupervisorAccount />,
    title: 'Patients',
    route: 'patients',
    restricted: true,
  },
  {
    Icon: <Tune />,
    title: 'Admin',
    route: 'admin',
    View: <Admin />,
  },
  {
    Icon: <Forum />,
    title: 'Message with...',
    route: 'messages/:routedThreadId',
    View: <Messaging isStandAloneView />,
    Provider: MessagesProvider,
    useViewPaper: false,
    hiddenFromNav: true,
  },
  {
    Icon: <Reply />,
    title: 'Reply',
    route: 'message/:routedMessageId',
    View: <Messaging isStandAloneView />,
    useViewPaper: false,
    Provider: MessagesProvider,
    hiddenFromNav: true,
  },
]

const userNavigationLinks: NavLink[] = [
  {
    Icon: <AccountCircle />,
    title: 'Profile',
    route: 'account',
    View: <Profile />,
    Provider: VrHeadsetProvider,
  },
  {
    Icon: <ExitToApp />,
    title: 'Log Out',
    route: 'logout',
    View: <Logout />,
  },
]

const navigation = {
  naviagtionLinks,
  userNavigationLinks,
  allRoutes: [...naviagtionLinks, ...userNavigationLinks],
}

export const NavigationContext = createContext<NavigationContextType>({
  navigation,
})

export const NavigationProvider = ({ children }: { children?: ReactNode }) => {
  return (
    <NavigationContext.Provider
      value={{
        navigation,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export const useNavigation = () => useContext(NavigationContext)
