import { DeleteForever } from '@mui/icons-material'
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'

import { PeerGroupMedia } from '../../providers/PeerGroups/types'
import { themeColors } from '../../theme'
import { truncateFileName } from '../../utilities/HelperFunctions'
import { FileType, FileTypeIconMap } from '../ImageUpload/ImageUpload'

export interface FileListProps {
  files?: PeerGroupMedia[]
  handle360Change?: (checked: boolean, file: PeerGroupMedia) => void
  handleDelete?: (file: PeerGroupMedia) => void
  fullFileNames?: boolean
}

export const FileCard = ({
  file,
  fullFileNames,
}: {
  file: PeerGroupMedia
  fullFileNames: boolean
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '30px',
      opacity: file.markToDelete ? '50%' : '100%',
    }}
  >
    <Chip
      icon={FileTypeIconMap[file.fileType]}
      label={fullFileNames ? file.fileName : truncateFileName(file.fileName)}
      variant='outlined'
    />
  </Box>
)

export const FileCheckBox = ({
  file,
  handle360Change,
}: {
  file: PeerGroupMedia
  handle360Change?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '30px',
    }}
  >
    {(file.fileType === FileType.Photo || file.fileType === FileType.Video) &&
    handle360Change ? (
      <Checkbox checked={file.is360} onChange={handle360Change} size='small' />
    ) : (
      <Checkbox
        disabled
        checked={file.is360}
        onChange={handle360Change}
        size='small'
      />
    )}
  </Box>
)

export const FileList = ({
  files,
  handle360Change,
  handleDelete,
  fullFileNames = false,
}: FileListProps) => {
  return (
    <Box>
      {!!files?.length && (
        <Grid container>
          <Grid item xs={8}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: themeColors.gray20,
              }}
            >
              {handle360Change ? 'Media:' : 'File:'}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {handle360Change && (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '14px',
                  color: themeColors.gray20,
                }}
              >
                Delete
              </Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                color: themeColors.gray20,
              }}
            >
              Is 360?:
            </Typography>
          </Grid>
        </Grid>
      )}

      <Box>
        {files?.map((file) => (
          <Grid
            container
            key={file.fileName}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Grid item xs={8}>
              <FileCard
                key={file.fileName}
                file={file}
                fullFileNames={fullFileNames}
              />
            </Grid>
            <Grid item xs={2}>
              {handleDelete && !file.markToDelete && (
                <IconButton
                  size='small'
                  aria-haspopup='true'
                  onClick={() => {
                    handleDelete(file)
                  }}
                  color={file.markToDelete ? 'primary' : 'info'}
                  disabled={file.markToDelete}
                >
                  <DeleteForever
                    fontSize='medium'
                    color={file.markToDelete ? 'primary' : 'info'}
                  />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={2}>
              {!file.markToDelete && (
                <FileCheckBox
                  file={file}
                  handle360Change={
                    handle360Change
                      ? (e) => handle360Change(e.target.checked, file)
                      : undefined
                  }
                />
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  )
}
