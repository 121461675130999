export enum ViewType {
  List,
  Details,
  Add,
  Edit,
}

export enum AddOrUpdate {
  Add,
  Update,
  Details,
}

export type PeerGroupTableRow = {
  peerGroupId: number
  name: string
  moderators: string
  dayOfWeek: string
  startTime: string
  frequency: string
  seats: number
  filledSeats: number
  endDate?: string
  createdOnDate: string
  createdBy: string
}
