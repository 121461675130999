import { Note, Person } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { themeColors } from '../../theme'

interface FormInstructionProps {
  isAnnouncement?: boolean
}

export const FormInstructions = ({ isAnnouncement }: FormInstructionProps) => (
  <Box
    sx={{
      marginBottom: 2,
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Typography
      sx={{
        marginBottom: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Person fontSize='medium' />{' '}
      <span style={{ fontSize: 14, marginLeft: '5px' }}>
        = Visible to Patients, * = Required
      </span>
    </Typography>
    {isAnnouncement && (
      <Typography
        color={themeColors.darkBlue}
        sx={{
          marginBottom: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Note fontSize='medium' />
        <span style={{ fontSize: 14, marginLeft: '5px' }}>
          To insert patient name in the announcement, use {'{patientName}'}
        </span>
      </Typography>
    )}
  </Box>
)
