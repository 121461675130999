import { Box } from '@mui/material'
import { useState } from 'react'

import {
  AccessRoleList,
  Provider,
  ProviderList,
} from '../../../providers/ProvidersProvider/types'
import { EditProviderModal } from './partials/EditProviderModal'
import { ListView } from './partials/ListView.partial'
import { ViewType } from './types'

interface ProvidersViewProps {
  viewMode: ViewType
  providers: ProviderList | null
  providerAccessRoles: AccessRoleList | null
  selectedProvider: Provider | null
  editProviderModal: boolean
  setEditProviderModal: React.Dispatch<React.SetStateAction<boolean>>
  handleEditProviderModal: (selectedProviderID: number) => void
  handleEditProviderAccess: (
    selectedProvider: Provider,
    accessRoleId: number
  ) => Promise<void>
}

export const ProvidersView = ({
  viewMode,
  providers,
  providerAccessRoles,
  selectedProvider,
  editProviderModal,
  setEditProviderModal,
  handleEditProviderModal,
  handleEditProviderAccess,
}: ProvidersViewProps) => {
  const [isEditLoading, setIsEditLoading] = useState<boolean>(false)

  return (
    <Box>
      {selectedProvider && (
        // NCA-41: Created box for modal 'Deactivate Patient'
        <div className='modal-container'>
          <div className='deactivate-modal'>
            <EditProviderModal
              selectedProvider={selectedProvider}
              editProviderModal={editProviderModal}
              setEditProviderModal={setEditProviderModal}
              providerAccessRoles={providerAccessRoles}
              handleEditProviderAccess={handleEditProviderAccess}
              setIsEditLoading={setIsEditLoading}
            />
          </div>
        </div>
      )}
      {viewMode === ViewType.List && providers && providerAccessRoles && (
        <ListView
          providers={providers}
          providerAccessRoles={providerAccessRoles}
          selectedProvider={selectedProvider}
          editProviderModal={editProviderModal}
          setEditProviderModal={setEditProviderModal}
          handleEditProviderModal={handleEditProviderModal}
          isEditLoading={isEditLoading}
        />
      )}
    </Box>
  )
}
