import 'react-calendar/dist/Calendar.css'
import './Calendar.styles.scss'

import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  NavigateBefore,
  NavigateNext,
} from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'
import Calendar from 'react-calendar'

import {
  PeerGroupAppointment,
  PeerGroupScheduleDay,
} from '../../providers/PeerGroups/types'
import {
  Appointment,
  DayWithAppointments,
  ScheduleDay,
} from '../../providers/Schedule/types'
import { dateFormatters } from '../../utilities/HelperFunctions'
import { AppointmentCard, ButtonMode } from '../AppointmentCard/AppointmentCard'

interface ScheduleViewProps {
  schedule: ScheduleDay[] | PeerGroupScheduleDay[]
  scheduleMode?: any
  activeDay: Date | null
  setActiveDay: (activeDay: Date) => void
  daysWithAppointments?: DayWithAppointments[]
  removeEventFromCalendar?: (appointmentId: number) => void
  selectAppointment: (
    appointment: PeerGroupAppointment | Appointment
  ) => Promise<boolean>
}

export const ScheduleView = ({
  schedule,
  activeDay,
  setActiveDay,
  daysWithAppointments,
  removeEventFromCalendar,
  selectAppointment,
}: ScheduleViewProps) => {
  return (
    <Grid container sx={{ height: '100%', overflow: 'hidden' }}>
      <Grid
        item
        lg={5}
        md={4}
        sm={6}
        xs={12}
        sx={() => ({
          paddingTop: '2%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        })}
      >
        <Calendar
          onChange={(v: any) => {
            setActiveDay(v)
          }}
          calendarType='US'
          defaultActiveStartDate={activeDay ? new Date(activeDay) : new Date()}
          activeStartDate={activeDay ? new Date(activeDay) : new Date()}
          minDate={new Date('2022-01-01')}
          minDetail='decade'
          value={activeDay}
          nextLabel={<NavigateNext />}
          next2Label={<KeyboardDoubleArrowRight />}
          prevLabel={<NavigateBefore />}
          prev2Label={<KeyboardDoubleArrowLeft />}
          tileClassName={({ date }) => {
            if (!daysWithAppointments) return ''
            const isActiveDay: boolean = dayjs(date).isSame(activeDay, 'date')
            const shouldHaveDot = daysWithAppointments.filter(
              (x) =>
                x.day === date.getDate() &&
                date.getMonth() === (activeDay?.getMonth() ?? 0 + 1)
            ).length

            return shouldHaveDot
              ? `dot__indicator ${
                  isActiveDay
                    ? 'react-calendar__tile--active dot__indicator-white'
                    : 'dot__indicator-gray'
                }`
              : isActiveDay
              ? 'react-calendar__tile--active'
              : ''
          }}
          onActiveStartDateChange={({ activeStartDate }) => {
            setActiveDay(new Date(activeStartDate))
          }}
        />
      </Grid>
      <Grid
        item
        lg={7}
        md={8}
        sm={6}
        xs={12}
        sx={{ height: '100%', overflow: 'auto', paddingLeft: 2 }}
      >
        {schedule.length ? (
          schedule.map(({ day, appointments }) => (
            <Grid key={day.toString()}>
              <Typography
                variant='body2'
                sx={{
                  padding: '5px',
                  paddingTop: '15px',
                }}
              >
                {dateFormatters.prettyDate(day)}
              </Typography>
              {appointments.map(
                (appointment: Appointment | PeerGroupAppointment) => (
                  <AppointmentCard
                    buttonMode={
                      daysWithAppointments
                        ? ButtonMode.Details
                        : ButtonMode.Join
                    }
                    selectAppointment={selectAppointment}
                    addEventToCalendar={
                      daysWithAppointments ? undefined : selectAppointment
                    }
                    appointment={appointment}
                    key={appointment.id}
                    removeEventFromCalendar={removeEventFromCalendar}
                  />
                )
              )}
            </Grid>
          ))
        ) : (
          <Typography
            variant='body2'
            sx={{
              padding: '5px',
              paddingTop: '15px',
            }}
          >
            No {daysWithAppointments ? 'appointments' : 'sessions'} on this day.
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}
