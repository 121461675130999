import * as yup from 'yup'

import {
  FieldType,
  // OptionsSelector,
  SectionFields,
} from '../../../utilities/Forms/types'

export const ANNOUNCEMENT_GROUP_FIELDS: SectionFields = [
  {
    hasInstructions: true,
    fields: [
      {
        label: 'New Announcement',
        backendKey: 'topicName',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        validation: yup.string().required('Topic name is required'),
        watch: true,
        minWidthPercent: 30,
      },
      {
        label: 'Description',
        backendKey: 'description',
        isPatientVisible: true,
        required: true,
        isEditable: true,
        minWidthPercent: 40,
        minWidth: 400,
        fieldType: FieldType.TextArea,
        validation: yup.string().required('Description is required'),
      },
    ],
  },
]
