// import { startCase, toLower } from 'lodash'

import { Provider } from '../../../providers/ProvidersProvider/types'
// import { dateFormatters } from '../../../utilities/HelperFunctions'
import { ProviderTableRow } from './types'

// NEW PATIENTS FORMAT
// NCA-43: Format 'Patients' table
export const formatProvidersForTable = (
  provider: Provider
): ProviderTableRow => {
  // if (provider.accessRoles?.length > 0) {
  // let accessRoles = ``
  // provider.accessRoles.forEach((accessRole) => {
  //   accessRoles = accessRoles + '\n' + accessRole.description
  // })

  return {
    ...provider,
    providerId: provider.providerId,
    providerName: provider.name,
    roles: provider.roles,
    accessRoles: provider.accessRoles[0].description,
    isActive: provider.isActive,
    // deactivationDate: provider.deactivationDate!,
  }
  // } else {
  // return {
  //   ...provider,
  //   providerId: provider.providerId,
  //   providerName: provider.name,
  //   roles: provider.roles,
  //   accessRole: '',
  //   isActive: provider.isActive,
  //   // deactivationDate: provider.deactivationDate!,
  // }
  // }
}
