import { FC } from 'react'

import BannerLogo from './../../assets/BannerLogo.png'
import OculusCoverPortrait from './../../assets/OculusCoverPortrait .png'
import { useStyles } from './Auth.styles'

export const AuthSideView: FC = () => {
  const { classes } = useStyles()

  return (
    <div data-testid='auth' className={classes.container}>
      <img src={OculusCoverPortrait} alt='' role='presentation' />
    </div>
  )
}

export const AuthLogoView: FC = () => {
  const { classes } = useStyles()

  return (
    <div data-testid='auth-logo' className={classes.logoContainer}>
      <img src={BannerLogo} alt='' role='presentation' />
    </div>
  )
}
