import { Person } from '@mui/icons-material'
import { Box } from '@mui/material'

import { SchedulingLinkTabPanelProps } from '../types'
import { DetailRow } from './SchedulingLink.partial'

export const SchedulingLinkTabPanel = ({
  primaryScheduleLink,
  scheduleLinks,
}: SchedulingLinkTabPanelProps) => {
  return (
    <Box>
      {/* <DetailRow primaryLink={primaryScheduleLink && primaryScheduleLink} /> */}
      <Box
        sx={{
          display: 'flex',
          margin: '0px 0px 10px 0px',
          borderRadius: '8px',
          // minWidth: '300px',
          // maxWidth: '400px',
          fontSize: '12px',
          justifyContent: 'center',
        }}
      >
        <Person fontSize='small' /> = Visible to Patients{' '}
      </Box>
      {scheduleLinks?.map((sl, i) => (
        <div key={i}>
          <DetailRow
            scheduleLinkObj={sl}
            linkVisibleToPatient={sl.isVisibleToPatient}
          />
        </div>
      ))}
    </Box>
  )
}
