import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { themeColors } from '../../theme'

export const useStyles = makeStyles()((theme: Theme) => ({
  appointment__card: {
    minWidth: theme.spacing(28),
    minHeight: theme.spacing(7),
    borderRadius: theme.spacing(0.8),
    padding: '3px',

    '&:hover': {
      backgroundColor: themeColors.blue90,
      cursor: 'pointer',
    },
  },
  appointment__card_cancelled: {
    backgroundColor: themeColors.red95,
    margin: '3px',

    '&:hover': {
      backgroundColor: themeColors.red95,
      cursor: 'auto',
    },
  },
  appointment__card__leftContent: {
    borderRight: `3px solid ${themeColors.gray60}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(0.7),
    paddingRight: theme.spacing(1),

    '& img': {
      height: '15px',
    },
  },
  appointment__card__leftContent_canceled: {
    borderRight: `3px solid ${themeColors.red}`,
  },
  appointment__card__rightContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(0.7),
    paddingLeft: theme.spacing(1),
  },
  appointment__card__leftContent__startTime: {
    color: themeColors.grayblack,
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  appointment__card__leftContent__duration: {
    color: themeColors.gray40,
    fontSize: '10px',
    fontWeight: '400',
  },
  appointment__card__rightContent__title: {
    fontSize: '13px',
    fontWeight: '700',
  },
  appointment__card__rightContent__participants: {
    fontSize: '11px',
    fontWeight: '500',
    color: themeColors.gray40,
  },
}))
