import { Location, useLocation } from 'react-router-dom'

import { DashboardView } from './Dashboard.view'
import { DashboardWidgets } from './Widgets'

const Dashboard = () => {
  const location: Location = useLocation()
  const searchParams = location.state?.searchParams

  return <DashboardView widgets={DashboardWidgets} seedParams={searchParams} />
}

export { Dashboard }
