import { Auth, Storage } from 'aws-amplify'
import { Dispatch, SetStateAction } from 'react'

import { SaveProgress } from '../views/Profile/types'

export const uploadFileToAWS = async ({
  name,
  path,
  file,
  setSaveProgress,
  bucket,
}: {
  name: string
  path: string
  file: File
  setSaveProgress?: Dispatch<SetStateAction<SaveProgress>>
  bucket?: string
}) => {
  const altBucket = bucket ? { bucket, region: 'us-east-1' } : {}
  try {
    const results = await Storage.put(`${path}/${name}`, file, {
      ...altBucket,
      level: 'public',
      progressCallback(progress) {
        if (setSaveProgress) {
          setSaveProgress(
            progress.loaded < progress.total
              ? SaveProgress.InProgress
              : SaveProgress.Completed
          )
        }
      },
    })
    return results
  } catch (err) {
    throw new Error('Unable to upload')
  }
}

export const getImageUrl = async (
  rawUrl: string | null
): Promise<string | null> => {
  Auth.currentSession()
  return !rawUrl || rawUrl.startsWith('http')
    ? rawUrl
    : await Storage.get(rawUrl)
}
