import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import {
  AnnouncementContextInterface,
  SendNotificationRequestData,
  TopiclistResponseData,
} from './types'

export const AnnouncementContext =
  createContext<AnnouncementContextInterface | null>(null)

export const AnnouncementProvider = ({
  children,
}: {
  children?: ReactNode
}) => {
  const [topics, setTopics] = useState<TopiclistResponseData>([])

  const { fetch } = useAxios()

  const getListOfTopics = useCallback(async () => {
    const { data } = await fetch({
      path: 'Notification/GetListofAllTopics',
    })
    if (data) {
      setTopics(data)
    }
  }, [])

  const sendNewNotification = async (
    notificationRquestData: SendNotificationRequestData
  ) => {
    console.log(
      'sendNewNotification notificationRquestData:',
      notificationRquestData
    )
    const { error } = await fetch({
      path: `Notification/AddNewTopic`,
      methodType: 'POST',

      body: notificationRquestData,
    })
    getListOfTopics()
    console.log('error', error)
    if (error) {
      throw new Error(`Error in sendMessage.`)
    }
  }

  useEffect(() => {
    getListOfTopics()
  }, [])

  return (
    <AnnouncementContext.Provider
      value={{
        topics,
        sendNewNotification,
        getListOfTopics,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  )
}

export const useAnnouncement = () =>
  useContext(AnnouncementContext) as AnnouncementContextInterface
