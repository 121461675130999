import { KeyboardArrowDown, Menu as MenuList } from '@mui/icons-material'
import {
  AppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material'
import { MouseEvent, useState } from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'

import { NavLink as NavLinkType } from '../../providers/Navigation/types'
import { ProviderUser } from '../../providers/ProviderDetails/types'
import { NavLink } from '../NavLink/NavLink'
import BannerLogo from './../../assets/BannerLogo.png'
import { useStyles } from './Header.styles'

export type HeaderProps = {
  pages?: NavLinkType[]
  submenuPages?: NavLinkType[]
  user: ProviderUser | null
}

export const Header = ({
  pages = [],
  submenuPages = [],
  user,
}: HeaderProps) => {
  const { classes } = useStyles()
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const [anchorElUser, setAnchorElUser] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null)

  const handleOpenUserMenu = (event: MouseEvent) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <div data-testid='header'>
      <AppBar position='static'>
        <Toolbar disableGutters className={classes.toolbar}>
          <Box
            sx={{
              display: { xs: 'none', lg: 'block' },
              mr: 1,
            }}
            className={classes.logoContainer}
          >
            <img src={BannerLogo} alt='' />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'block', lg: 'none' } }}>
            <IconButton
              size='large'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='secondary'
            >
              <MenuList fontSize='small' color='secondary' />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={!!anchorElNav}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', lg: 'none' },
              }}
            >
              {pages
                .filter((p) => !p.restricted && !p.hiddenFromNav)
                .map((page) => (
                  <RouterNavLink
                    to={'/' + page.route}
                    key={`subroute-${page.route}`}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign='center'>{page.title}</Typography>
                    </MenuItem>
                  </RouterNavLink>
                ))}
            </Menu>
          </Box>
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex', lg: 'none' },
              mr: 1,
              flexGrow: 6,
              justifyContent: 'center',
            }}
          >
            <img src={BannerLogo} alt='' className={classes.logoContainer} />
          </Box>

          <Box sx={{ flexGrow: 6, display: { xs: 'none', lg: 'flex' } }}>
            {pages
              .filter((page) => !page.restricted && !page.hiddenFromNav)
              .map((page, i) => (
                <Grid item key={`page${i}`}>
                  <NavLink {...page} />
                </Grid>
              ))}
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              height: '100%',
              display: 'flex',
            }}
          >
            <Box
              sx={{
                width: '95%',
                display: 'flex',
                flexWrap: 'none',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
              onClick={handleOpenUserMenu}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <KeyboardArrowDown fontSize='medium' color='secondary' />
                <Typography
                  fontSize={14}
                  fontWeight={700}
                  style={{ marginLeft: '15px' }}
                >
                  Hello, {user?.preferredFirstName || user?.firstName}
                </Typography>
              </Box>

              <Avatar
                alt={user?.preferredFirstName || user?.firstName}
                src={user?.photoUrl}
                style={{ marginLeft: '25px' }}
              />
            </Box>

            {!!submenuPages.length && (
              <Menu
                id='menu-appbar'
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={!!anchorElUser}
                onClose={handleCloseUserMenu}
              >
                {submenuPages
                  .filter((page) => !page.restricted && !page.hiddenFromNav)
                  .map((page) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <RouterNavLink
                      to={'/' + page.route}
                      key={`subroute-${page.route}`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                        fontSize: 12,
                      }}
                    >
                      <MenuItem onClick={() => handleCloseUserMenu}>
                        <ListItemIcon>{page.Icon}</ListItemIcon>
                        <ListItemText secondary={page.title} />
                      </MenuItem>
                    </RouterNavLink>
                  ))}
              </Menu>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  )
}
