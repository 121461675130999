import { Box, Button, Container, Typography } from '@mui/material'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridComparatorFn,
} from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { Dispatch, SetStateAction } from 'react'

import { TopiclistResponseData } from '../../../../providers/Announcement/types'
import { themeColors } from '../../../../theme'
import { dateFormatters } from '../../../../utilities/HelperFunctions'
import { ViewAnnouncementModal } from './ViewAnnouncementsModal'

interface AnnouncementViewProps {
  deliveredAnnouncements: TopiclistResponseData
  handleRowClickTopicModal: (DescriptionId: string) => void
  topicId: string | null
  showViewAnnouncementModal: boolean
  setShowViewAnnouncementModal: Dispatch<SetStateAction<boolean>>
}

const dayInMonthComparator: GridComparatorFn<Date> = (v1, v2) =>
  new Date(v1) < new Date(v2) ? -1 : 1

const COLUMNS: GridColDef[] = [
  {
    field: 'dateSent',
    headerName: 'Sent On',
    minWidth: 180,
    type: 'date',
    sortComparator: dayInMonthComparator,
  },
  { field: 'name', headerName: 'Announcement', minWidth: 300 },
  { field: 'sentBy', headerName: 'Sent By', minWidth: 120 },
].map((col) => ({ ...col, disableColumnMenu: true }))

export const AnnouncementView = ({
  deliveredAnnouncements,
  handleRowClickTopicModal,
  topicId,
  setShowViewAnnouncementModal,
  showViewAnnouncementModal,
}: AnnouncementViewProps) => {
  const [formattedAnnouncements, setFormattedAnnouncements] = useState<
    TopiclistResponseData | []
  >([])

  useEffect(() => {
    setFormattedAnnouncements(
      deliveredAnnouncements.map((deliveredAnnouncement) => ({
        ...deliveredAnnouncement,
        dateSent: dateFormatters.prettyDate(deliveredAnnouncement.dateSent),
      }))
    )
  }, [deliveredAnnouncements])

  const [dataColumns, setDataColumns] = useState<GridColDef[]>(COLUMNS)

  useEffect(() => {
    if (formattedAnnouncements) {
      const dataColumns = COLUMNS.map((col) =>
        col.field === 'name'
          ? {
              ...col,
              renderCell: (params: GridCellParams) => (
                <Box sx={{ minWidth: '100%' }}>
                  <Button
                    sx={{
                      height: '100%',
                      overflowWrap: 'wrap',
                      minWidth: '100%',
                    }}
                    variant='text'
                    onClick={() =>
                      handleRowClickTopicModal(params.row.description)
                    }
                  >
                    <Typography
                      sx={{
                        color: themeColors.blue40,
                        fontSize: '14px',
                        maxWidth: '100%',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        textAlign: 'left',
                        minWidth: '100%',
                      }}
                    >
                      {params.value}
                    </Typography>
                  </Button>
                </Box>
              ),
            }
          : col
      )
      setDataColumns(dataColumns)
    }
  }, [formattedAnnouncements])

  return (
    <Container
      data-testid='announcement'
      sx={{ height: '100%', width: '100%' }}
    >
      <ViewAnnouncementModal
        showViewAnnouncementModal={showViewAnnouncementModal}
        setShowViewAnnouncementModal={setShowViewAnnouncementModal}
        description={topicId}
      />
      <DataGrid
        getRowId={(row: any) => row.id}
        rows={formattedAnnouncements}
        columns={dataColumns}
        disableColumnFilter={true}
        isRowSelectable={() => false}
        sx={{
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 400,
          minHeight: '200px',
          fontSize: '14px',
          lineHeight: '100%',
          verticalAlign: 'center',
          border: 'none',
          '& .MuiDataGrid-cell:active, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:hover':
            {
              border: 'none',
              outline: 'none',
            },
          '& .MuiTableCell-root': {
            border: 'none',
            outline: 'none',
          },
          '& .MuiDataGrid-cell:hover': {
            pointer: 'cursor',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderTitleContainer:focus, .MuiDataGrid-columnHeaderTitleContainer:focus-within':
            {
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '100%',
              textTransform: 'uppercase',
              border: 'none',
              outline: 'none',
            },
        }}
      />
    </Container>
  )
}
