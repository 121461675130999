import { Container, Paper } from '@mui/material'
import { ReactNode } from 'react'

import { Header } from '../../components'
import { useNavigation } from '../../providers/Navigation/Navigation.provider'
import { ProviderUser } from '../../providers/ProviderDetails/types'
import { useStyles } from '../Main/Main.styles'

export const MainView = ({
  usePaper = true,
  myProviderDetails,
  children,
}: {
  usePaper?: boolean
  myProviderDetails: ProviderUser | null
  children?: ReactNode
}) => {
  const { classes } = useStyles()
  const { navigation } = useNavigation()

  return (
    <div data-testid='main'>
      <Header
        pages={navigation!.naviagtionLinks}
        submenuPages={navigation!.userNavigationLinks}
        user={myProviderDetails}
      />
      {myProviderDetails?.accessRoles &&
      myProviderDetails?.accessRoles[0].description === 'Deactivated' ? (
        <h1 style={{ margin: '20vh', textAlign: 'center' }}>
          Your account has been suspended. Please contact your administrator if
          you believe this to be a mistake.
        </h1>
      ) : (
        <>
          <Container className={classes.mainView}>
            {usePaper ? (
              <Paper sx={{ padding: 5, height: '100%', overflow: 'auto' }}>
                <div className={classes.viewWrapper}>
                  <>{children}</>
                </div>
              </Paper>
            ) : (
              <div className={classes.viewWrapper}>
                <>{children}</>
              </div>
            )}
          </Container>
        </>
      )}
    </div>
  )
}
