import { Box, Paper, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'

import { useStyles } from './Widget.styles'

export interface WidgetProps {
  title: string
  Buttons?: FC[]
  children?: ReactNode
  usePadding?: boolean
}

export const Widget = ({
  title,
  Buttons,
  children,
  usePadding = true,
}: WidgetProps) => {
  const { classes } = useStyles()

  return (
    <Paper sx={{ height: '100%' }}>
      <div className={classes.widgetHeader}>
        <Box sx={{ flexGrow: 2 }}>
          <Typography variant='h6'>{title.toUpperCase()}</Typography>
        </Box>
        <Box display='flex'>
          {Buttons &&
            Buttons.map((Button, i) => (
              <Box key={`widget-btn-${i}`} paddingLeft='10px'>
                <Button />
              </Box>
            ))}
        </Box>
      </div>
      <Box
        sx={{ padding: usePadding ? '15px' : '0px' }}
        className={classes.widgetContent}
      >
        {children}
      </Box>
    </Paper>
  )
}
