import { useAuthenticator } from '@aws-amplify/ui-react'
import { ReactNode } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

export const RequireAuth = ({ children }: { children?: ReactNode }) => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { route } = useAuthenticator((context) => [context.route])
  const go = useNavigate()

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: () => {
      go('/logout')
    },
  })

  if (route !== 'authenticated') {
    return (
      <Navigate
        to='/'
        state={{
          from: location,
          searchParams: Object.fromEntries(searchParams),
        }}
        replace
      />
    )
  }

  return <>{children}</>
}
