import dayjs from 'dayjs'
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  divideScheduleIntoDaysWithAppointments,
  getStartAndEndDateFromMonthYear,
} from '../../utilities/HelperFunctions'
import { useAxios } from '../../utilities/Requests/useAxios'
import { getImageUrl } from '../../utilities/Storage'
import { Appointment, DayWithAppointments } from './types'

export interface ScheduleContextInterface {
  sevenDayAppointments: Appointment[]
  daysWithAppointments: DayWithAppointments[]
  get7dayAppointments: (startDate: Date, numberOfDays?: number) => Promise<any>
  getDaysWithAppointments: (month: number, year: number) => Promise<any>
  hideAppointment: (appointmentId: number) => Promise<any>
  removePatientFromCurrentSession: (
    patientMRN: string,
    appointmentId: number
  ) => Promise<any>
  removePatientFromAllSessions: (
    patientMRN: string,
    peerGroupId: number
  ) => Promise<any>
  scheduleMode: ScheduleMode
  scheduleModeInverse: ScheduleMode
  setScheduleMode: React.Dispatch<ScheduleMode>
}

export enum ScheduleMode {
  MyAppointments = 'My Appointments',
  AllPeerGroups = 'All VR Meetings',
}

export const ScheduleContext = createContext<ScheduleContextInterface | null>(
  null
)

export const ScheduleProvider = ({ children }: { children?: ReactNode }) => {
  const { fetch } = useAxios()

  const [scheduleMode, setScheduleMode] = useState<ScheduleMode>(
    ScheduleMode.MyAppointments
  )

  const [scheduleModeInverse, setScheduleModeInverse] = useState<ScheduleMode>(
    ScheduleMode.AllPeerGroups
  )

  useEffect(() => {
    setScheduleModeInverse(
      scheduleMode === ScheduleMode.AllPeerGroups
        ? ScheduleMode.MyAppointments
        : ScheduleMode.AllPeerGroups
    )
  }, [scheduleMode])

  const [sevenDayAppointments, set7dayAppointments] = useState<Appointment[]>(
    []
  )
  const [daysWithAppointments, setDaysWithAppointments] = useState<any>([])

  const get7dayAppointments = async (startDate: Date, numberOfDays = 1) => {
    try {
      const { data } = await fetch({
        path: `Schedule/GetSchedule?StartDate=${dayjs(startDate.toDateString())
          .subtract(1, 'day')
          .toISOString()}&NumberOfDays=${3}&PeerGroupsOnly=false&ScheduleMode=${scheduleMode}`,
      })
      if (data) {
        const modifiedForDisplay = await data
          .filter(
            (appointment: Appointment) =>
              dayjs(appointment.startTime).date() === dayjs(startDate).date()
          )
          .map(async (appointment: Appointment) => {
            const participants = appointment.participants.map(async (p) => ({
              ...p,
              participantPhotoURL: await getImageUrl(p.participantPhotoURL),
            }))

            const observers =
              appointment.observers?.map(async (p) => ({
                ...p,
                participantPhotoURL: await getImageUrl(p.participantPhotoURL),
              })) || []

            const patients = appointment.patients?.map(async (p) => ({
              ...p,
              photoURL: await getImageUrl(p.photoURL),
            }))

            if (appointment.peerGroupId) {
              const { data } = await fetch({
                path: `PeerGroup/GetPeerGroupForEdit?peerGroupId=${appointment.peerGroupId}`,
              })

              if (data && data.seats) {
                appointment.seatsAvailable = data.seats
              }
            }

            return {
              ...appointment,
              id: appointment.appointmentId,
              name: appointment.appointmentName,
              participants: await Promise.all(participants),
              observers: await Promise.all(observers),
              patients: await Promise.all(patients),
            }
          })
        const finalizedAppointments = await Promise.all(modifiedForDisplay)
        set7dayAppointments([...finalizedAppointments])
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  const getDaysWithAppointments = async (month: number, year: number) => {
    setDaysWithAppointments([])
    const { startDate, endDate } = getStartAndEndDateFromMonthYear(month, year)

    try {
      const { data } = await fetch({
        path: `Schedule/GetScheduledDateTimes?StartDate=${startDate}&EndDate=${endDate}&PeerGroupsOnly=false&ScheduleMode=${scheduleMode}`,
      })
      if (data) {
        const sortedDays = divideScheduleIntoDaysWithAppointments(data)
        setDaysWithAppointments(sortedDays)
      }
    } catch (e: any) {
      console.error(e)
    }
  }

  const hideAppointment = async (appointmentId: number) => {
    try {
      await fetch({
        path: `Schedule/HideAppointment`,
        methodType: 'POST',
        body: { appointmentId },
      })
    } catch (e: any) {
      console.error(e)
    }
  }

  const removePatientFromAllSessions = useCallback(
    async (patientMRN: string, appointmentId: number) => {
      const { error } = await fetch({
        path: `PeerGroup/RemovePatientFromAllSessions?patientMRN=${patientMRN}&peerGroupSessionId=${appointmentId}`,
        methodType: 'POST',
      })
      if (error) {
        throw new Error(`Error in removing patient from peer group sessions.`)
      }
    },
    []
  )

  const removePatientFromCurrentSession = useCallback(
    async (patientMRN: string, appointmentId: number) => {
      const { error } = await fetch({
        path: `PeerGroup/RemovePatientFromCurrentSession?patientMRN=${patientMRN}&peerGroupSessionId=${appointmentId}`,
        methodType: 'POST',
      })
      if (error) {
        throw new Error(`Error in removing patient from peer group session.`)
      }
    },
    []
  )

  return (
    <ScheduleContext.Provider
      value={{
        sevenDayAppointments,
        daysWithAppointments,
        get7dayAppointments,
        getDaysWithAppointments,
        hideAppointment,
        removePatientFromCurrentSession,
        removePatientFromAllSessions,
        scheduleMode,
        scheduleModeInverse,
        setScheduleMode,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  )
}

export const useSchedule = () =>
  useContext(ScheduleContext) as ScheduleContextInterface
