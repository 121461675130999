import { Box, Button, TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Modal } from '../../../../components'
// import { usePatients } from '../../../../providers/Patients/Patients.provider'
import { Patient } from '../../../../providers/Patients/types' // PatientList
import { themeColors } from '../../../../theme'

// Create interface for DeactivatePatientModalProps
interface DeactivatePatientModalProps {
  setShowDeactivatePatientModal: Dispatch<SetStateAction<boolean>>
  showDeactivatePatientModal: boolean
  handleDeactivatePatient: (
    selectedPatient: Patient,
    selectedDate: Date
    // approved: boolean
  ) => Promise<boolean>
  selectedPatient: Patient
}

export const DeactivatePatientModal = ({
  selectedPatient,
  showDeactivatePatientModal,
  setShowDeactivatePatientModal,
  handleDeactivatePatient,
}: DeactivatePatientModalProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  // NCA-34, default date selected is the next day from today
  useEffect(() => {
    if (!selectedDate) {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(today.getDate() + 1)
      setSelectedDate(tomorrow)
    }
  }, [selectedDate])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal
        open={showDeactivatePatientModal}
        setOpen={setShowDeactivatePatientModal}
        title={'DEACTIVATE PATIENT FROM PROGRAM'}
        width={'30%'}
        ModalContent={
          <Box sx={{ width: '92%', margin: 'auto' }}>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '19px',
                color: themeColors.grayblack,
              }}
            >
              Are you sure you want to deactivate{' '}
              {selectedPatient?.preferredName} from the program?
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: themeColors.gray20,
                marginTop: '10px',
              }}
            >
              REQUIRED: Please select a deactivation date.
            </Typography>
            <DatePicker
              // Update date for it to be tomo
              value={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size='small'
                  variant='outlined'
                  sx={{ height: '60px' }}
                />
              )}
            />
            {/* {console.log('selectedDate in Modal:', new Date(selectedDate!))} */}
            <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
              <Button
                variant='contained'
                onClick={() => setShowDeactivatePatientModal(false)}
                sx={{ marginRight: '10px' }}
              >
                No
              </Button>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: themeColors.gray20,
                }}
              >
                {/* //NCA-34 changed to fit better phrasing */}
                {/* Close window and return to patient view. */}
                Do not deactivate patient.
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='error'
                onClick={() => {
                  setShowDeactivatePatientModal(false)
                  handleDeactivatePatient(
                    selectedPatient,
                    new Date(selectedDate!)
                  )
                }}
                sx={{ marginRight: '10px' }}
              >
                Yes
              </Button>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: themeColors.gray20,
                }}
              >
                Deactivate <strong>{selectedPatient?.preferredName}</strong>{' '}
                from the program.
              </Typography>
            </Box>
          </Box>
        }
      />
    </LocalizationProvider>
  )
}
