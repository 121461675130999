import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'

import {
  Contact,
  PatientOrProvider,
  PopOutUser,
  ScheduleLink,
} from '../../components/PatientOrProviderDetails/types'
import { getRandomProviderRole } from '../../utilities/Mocking/UserAttributes'
import { useAxios } from '../../utilities/Requests/useAxios'
import { getImageUrl } from '../../utilities/Storage'
import { States } from '../Options/types'
import { useUser } from '../User/User.provider'
import {
  ProviderDetails,
  ProviderDetailsContextInterface,
  ProviderInformation,
  ProviderUser,
  UpdateProviderDetails,
} from './types'

export const ProviderDetailsContext =
  createContext<ProviderDetailsContextInterface>({
    user: null,
    myContact: null,
    scheduleLinks: null,
    myProviderDetails: null,
    //@ts-ignore
    providerInformation: {}, //for recipient popover
    updateProvider: async (newProvider) => ({}),
    getMyProviderDetails: async () => ({}),
    //@ts-ignore
    getProviderInformation: async (providerId: number) => Promise,
  })

export const ProviderDetailsProvider = ({
  children,
}: {
  children?: ReactNode
}) => {
  const myProviderDetailsReducer = (
    state: { providerDetails: ProviderUser | null },
    action: { type: any; payload: any }
  ) => {
    switch (action.type) {
      case 'GET':
        return state.providerDetails
          ? { ...state.providerDetails, ...action.payload }
          : action.payload
      default:
        throw new Error()
    }
  }

  const { user } = useUser()
  const { fetch } = useAxios()
  const [userContactInfo, setUserContactInfo] = useState<Contact | null>(null)
  const [basicUser, setBasicUser] = useState<PopOutUser | null>(null)

  const [providerInformation, setProviderInformation] =
    useState<ProviderInformation | null>(null)

  const [myProviderDetails, dispatch] = useReducer(myProviderDetailsReducer, {})

  const getMyProviderDetails = useCallback(async () => {
    const { data, error } = await fetch({
      path: 'Provider/GetProviderDetails',
    })

    const updatedDetails = {
      ...data,
      originalPhotoUrl: data.photoUrl,
      photoUrl: data?.photoUrl
        ? await getImageUrl(data?.photoUrl)
        : data?.photoUrl,
      priviledgedStateDisplay: data?.priviledgedStates
        ?.map((state: States) => state.abbreviation)
        .join(', '),
    }

    const providerUser: ProviderUser = {
      ...user,
      ...updatedDetails,
    }

    if (error) {
      throw new Error(`Error in getting provider detail links.`)
    }

    setBasicUser({
      name: data?.name,
      photoUrl: data?.photoUrl,
      patientOrProvider: PatientOrProvider.Provider,
      // role: data.role, //TODO: Get Sam to add Role to provider details
      providerRole: getRandomProviderRole(),
    })

    dispatch({ type: 'GET', payload: providerUser })
    return providerUser
  }, [])

  const getMyContactInfo = useCallback(async () => {
    const { data, error } = await fetch({
      path: 'Provider/GetProviderDetails',
    })

    if (error) {
      throw new Error(`Error in getting contact info.`)
    }

    setUserContactInfo({
      email: data?.emailAddress,
      companyPhone: data?.companyPhone,
      personalPhone: data?.personalPhone,
      timeZone: data?.timeZone,
    })
  }, [])

  // get the provider schedule links for the recipient popover
  const getProviderInformation = useCallback(
    async (providerId: number | undefined) => {
      const { data, error } = await fetch({
        path: `Provider/GetProviderInformation?providerId=${providerId}`,
      })

      if (error) {
        throw new Error(`Error in getting provider scheduling links.`)
      }

      setProviderInformation({
        id: data?.id,
        name: data?.name,
        photoUrl: data?.photoUrl,
        primaryGRemindersLink: data?.primaryGRemindersLink,
        gRemindersEventTypeLinks: data?.gRemindersEventTypeLinks?.map(
          (sl: ScheduleLink) => ({
            id: sl.id,
            meetingName: sl.eventTypeName,
            meetingUrl: sl.scheduleLink,
            duration: sl.durationInMinutes,
            isVisibleToPatient: sl.isVisibleToPatient,
          })
        ),
      })
    },
    []
  )

  const updateProvider = useCallback(
    async (newProvider: UpdateProviderDetails) => {
      const { error } = await fetch({
        path: 'Provider/UpdateProviderDetails',
        methodType: 'POST',
        body: newProvider,
      })
      if (error) {
        throw new Error(`Error in updating provider.`)
      }
      return await getMyProviderDetails()
    },
    []
  )

  const updateProviderPhoto = useCallback(async (photoURL: string) => {
    const { error } = await fetch({
      path: `Provider/UpdateProviderProfilePicture?photoURL=${photoURL}`,
      methodType: 'POST',
    })
    if (error) {
      throw new Error(`Error in updating provider.`)
    }
    return await getMyProviderDetails()
  }, [])

  const updateGReminderEventVisibility = useCallback(
    async (eventId: number, isVisibleToPatient: boolean) => {
      const { error } = await fetch({
        path: `Provider/UpdateGReminderEventPatientVisibility?providerEventTypeId=${eventId}&isVisibleToPatient=${isVisibleToPatient}`,
        methodType: 'PUT',
      })
      if (error) {
        throw new Error(`Error in updating provider.`)
      }
      const providerDetails = await getMyProviderDetails()
      const result = await getProviderInformation(providerDetails?.providerId)
      return result
    },
    []
  )

  useEffect(() => {
    if (user?.getUsername()) {
      getMyProviderDetails()
      getMyContactInfo()
      getProviderInformation(myProviderDetails.providerId)
    }
  }, [user?.getUsername(), myProviderDetails.providerId])

  return (
    <ProviderDetailsContext.Provider
      value={{
        user: basicUser!,
        myProviderDetails: myProviderDetails,
        myContact: userContactInfo,
        providerInformation: providerInformation,
        updateProvider,
        updateProviderPhoto,
        updateGReminderEventVisibility,
        getMyProviderDetails: getMyProviderDetails,
        getProviderInformation: getProviderInformation,
      }}
    >
      {children}
    </ProviderDetailsContext.Provider>
  )
}

export const useProviderDetails = () => useContext(ProviderDetailsContext)
