import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { PeerGroupMapper } from '../../utilities/Requests/apiMappers'
import { useAxios } from '../../utilities/Requests/useAxios'
import { getImageUrl } from '../../utilities/Storage'
import { PeerGroupAppointment, PeerGroupDetails, PeerGroupList } from './types'

export interface PeerGroupsContextInterface {
  peerGroups: PeerGroupList | null
  peerGroupDetails: PeerGroupDetails | null
  peerGroupSchedule: PeerGroupAppointment[] | null
  getPeerGroupList: () => Promise<PeerGroupList>
  getPeerGroupDetails: (
    peerGroupId?: number
  ) => Promise<PeerGroupDetails | null>
  addPeerGroup: (newPeerGroup: PeerGroupDetails) => Promise<any>
  updatePeerGroup: (updatePeerGroup: PeerGroupDetails) => Promise<any>
  getPeerGroupSchedule: (startDate: Date) => Promise<any>
  joinPeerGroupSession: (
    peerGroupSessionId: number,
    startDate: string
  ) => Promise<any>
  deletePeerGroup: (peerGroupId: number) => Promise<any>
  deletePeerGroupSession: (peerGroupSessionId: number) => Promise<any>
  joinVRSession: (peerGroupSessionId: number) => Promise<any>
}

export const PeerGroupsContext =
  createContext<PeerGroupsContextInterface | null>(null)

export const PeerGroupsProvider = ({ children }: { children?: ReactNode }) => {
  const [peerGroups, setPeerGroups] = useState(null)
  const [peerGroupDetails, setPeerGroupDetails] = useState(null)
  const [peerGroupSchedule, setPeerGroupSchedule] = useState<
    PeerGroupAppointment[] | null
  >(null)

  const { fetch } = useAxios()

  const getPeerGroupList = useCallback(async () => {
    const { data, error } = await fetch({
      path: 'PeerGroup/GetListOfPeerGroups',
    })
    if (data) {
      setPeerGroups(data)
      return data
    }
    if (error) {
      throw new Error(`Error in getting peer group list.`)
    }
  }, [])

  const getPeerGroupDetails = useCallback(async (peerGroupId?: number) => {
    if (!peerGroupId) {
      setPeerGroupDetails(null)
      return null
    }

    const { data, error } = await fetch({
      path: `PeerGroup/GetPeerGroupForEdit?peerGroupId=${peerGroupId}`,
    })
    if (data) {
      const updatedDetails = {
        ...data,
        imageUrlForDisplay: await getImageUrl(data?.imageUrl),
      }

      updatedDetails['minutesBefore,minutesAfter'] = [
        `${data.minutesBefore}`,
        `${data.minutesAfter}`,
      ]
      const mappedVals = PeerGroupMapper.FromAPI(updatedDetails)
      setPeerGroupDetails(mappedVals)
      return updatedDetails
    }
    if (error) {
      throw new Error(
        `Error in getting peer group details for ID: ${peerGroupId}.`
      )
    }
  }, [])

  const addPeerGroup = useCallback(async (newPeerGroup: PeerGroupDetails) => {
    const { data, error } = await fetch({
      path: `PeerGroup/AddPeerGroup`,
      methodType: 'POST',
      body: newPeerGroup,
    })
    if (data) {
      await getPeerGroupList()
    }
    if (error) {
      throw new Error(`Error in adding peer group.`)
    }
  }, [])

  const updatePeerGroup = useCallback(
    async (updatedPeerGroup: PeerGroupDetails) => {
      const { data, error } = await fetch({
        path: `PeerGroup/UpdatePeerGroup`,
        methodType: 'PUT',
        body: updatedPeerGroup,
      })
      if (data) {
        await getPeerGroupList()
        await getPeerGroupDetails(updatedPeerGroup.peerGroupId!)
      }
      if (error) {
        throw new Error(`Error in updating peer group.`)
      }
    },
    []
  )

  const joinVRSession = useCallback(async (peerGroupSessionId: number) => {
    const { error } = await fetch({
      path: `PeerGroup/JoinPeerGroupSession`,
      methodType: 'PUT',
      body: { peerGroupSessionId },
    })
    if (error) {
      throw new Error(`Error in joining VR session.`)
    }
  }, [])

  const getPeerGroupSchedule = useCallback(async (startDate: Date) => {
    const { data, error } = await fetch({
      path: `PeerGroup/GetPeerGroupSessionSchedule?NumberOfDays=1&StartDate=${new Date(
        startDate.toDateString()
      ).toISOString()}`,
    })
    if (data) {
      const fromApiData = data.map(
        (peerGroupAppointment: PeerGroupAppointment) => ({
          ...peerGroupAppointment,
          id: peerGroupAppointment.peerGroupId,
          isVr: true,
        })
      )
      setPeerGroupSchedule(fromApiData)
    }
    if (error) {
      throw new Error(`Error in loading peer group schedule.`)
    }
  }, [])

  const joinPeerGroupSession = useCallback(
    async (peerGroupSessionId: number, startDate?: string) => {
      const { data, error } = await fetch({
        path: `PeerGroup/SignUpForPeerGroupSession?peerGroupSessionId=${peerGroupSessionId}`,
        methodType: 'POST',
      })

      if (data) {
        getPeerGroupSchedule(startDate ? new Date(startDate) : new Date())
      }
      if (error || !!data) {
        throw new Error(`Error in joining peer group session.`)
      }
    },
    []
  )

  const deletePeerGroup = useCallback(async (peerGroupId: number) => {
    const { data, error } = await fetch({
      path: `PeerGroup/DeletePeerGroup?peerGroupId=${peerGroupId}`,
      methodType: 'POST',
    })
    if (data) {
      await getPeerGroupList()
    }
    if (error) {
      throw new Error(`Error in deleting peer group.`)
    }
  }, [])

  const deletePeerGroupSession = useCallback(
    async (peerGroupSessionId: number) => {
      const { data, error } = await fetch({
        path: `PeerGroup/DeletePeerGroupSession?peerGroupSessionId=${peerGroupSessionId}`,
        methodType: 'POST',
      })
      if (data) {
        await getPeerGroupList()
      }
      if (error) {
        throw new Error(`Error in deleting peer group session.`)
      }
    },
    []
  )

  useEffect(() => {
    getPeerGroupList()
  }, [])

  return (
    <PeerGroupsContext.Provider
      value={{
        peerGroups,
        peerGroupDetails,
        peerGroupSchedule,
        getPeerGroupList,
        getPeerGroupDetails,
        addPeerGroup,
        updatePeerGroup,
        getPeerGroupSchedule,
        joinPeerGroupSession,
        deletePeerGroup,
        deletePeerGroupSession,
        joinVRSession,
      }}
    >
      {children}
    </PeerGroupsContext.Provider>
  )
}

export const usePeerGroups = () =>
  useContext(PeerGroupsContext) as PeerGroupsContextInterface
