export enum ViewType {
  List,
  Details,
  Add,
  Edit,
}

export type ProviderTableRow = {
  providerId: number
  providerName: string
  roles: Array<string>
  accessRoles: string
  isActive: boolean
}
