import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { Storage } from 'aws-amplify'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
// import htmlToDraft from 'html-to-draftjs'
import { SetStateAction, useEffect, useRef, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import { useMediaQuery } from 'react-responsive'

import AttachmentSvg from '../../../../assets/attachment.svg'
import FileSvg from '../../../../assets/file.svg'
import { FlagSvg } from '../../../../assets/FlagSvg'
import SendSvg from '../../../../assets/send.svg'
import TrashSvg from '../../../../assets/trash.svg'
import { PatientOrProviderDetails } from '../../../../components'
import { PatientOrProvider } from '../../../../components/PatientOrProviderDetails/types'
import { usePatientCareTeam } from '../../../../providers/CareTeam/PatientCareTeam.provider'
import { useMessages } from '../../../../providers/Messages/Messages.provider'
import {
  MessageThreadData,
  SendMessageData,
  UploadObj,
} from '../../../../providers/Messages/types'
import { useOptions } from '../../../../providers/Options/Options.provider'
import { RecipientOptionObj } from '../../../../providers/Options/types'
import { ProviderUser } from '../../../../providers/ProviderDetails/types'
import { themeColors } from '../../../../theme'
import { useStyles } from './Messaging.styles'

type MessageEditorProps = {
  backToMessages: () => void
  uploads: UploadObj[]
  setUploads: React.Dispatch<React.SetStateAction<UploadObj[]>>
  threadId?: number
  thread?: MessageThreadData
  setAttachmentKeys?: React.Dispatch<
    React.SetStateAction<{ id: number; key: string }[]>
  >
  setIsNewMessage?: React.Dispatch<React.SetStateAction<boolean>>
  isNewMessage?: boolean
  isStandAloneView?: boolean
  myProviderDetails: ProviderUser | null
}

const MessageEditor = ({
  backToMessages,
  uploads,
  setUploads,
  threadId,
  thread,
  setAttachmentKeys,
  setIsNewMessage,
  isNewMessage,
  isStandAloneView,
  myProviderDetails,
}: MessageEditorProps) => {
  const { classes } = useStyles()

  const isMobile = useMediaQuery({ query: '(max-width: 480px)' })

  const { getProviderMessagesInThread, sendMessage } = useMessages()
  const { getProviderContactInfo, providerContactInfo } = usePatientCareTeam()

  const editorRef = useRef(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [urgency, setUrgency] = useState(false)

  const [sendMessageData, setSendMessageData] = useState<SendMessageData>({
    toProviders: [],
    toPatient: 0,
    urgent: urgency,
    messageContent: '',
    attachments: [],
  })

  const [disableSend, setDisableSend] = useState(false)

  const [showDropdown, setShowDropdown] = useState(false)
  const [searchRecipientStr, setSearchRecipientStr] = useState('')

  // for patient care team / provider detail popover
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const [clickedPopover, setClickedPopover] = useState({
    isProvider: false,
    username: '',
    photoUrl: '',
    patientMrn: '',
    providerRole: '',
    providerOrPatientId: 0,
    uniqueId: '',
  })

  const { options } = useOptions()

  const initialRecipientOptions = [
    ...options.providers.map((opt) => ({
      ...opt,
      isProvider: true,
      selected: false,
    })),
    ...options.activePatients.map((opt) => ({
      ...opt,
      isProvider: false,
      selected: false,
    })),
  ]?.filter(
    (option: RecipientOptionObj) =>
      !(option.isProvider && option.apiId === myProviderDetails?.providerId)
  )
  const [recipientOptions, setRecipientOptions] = useState(
    initialRecipientOptions
  )

  const getSelected = (isSelected: boolean, arr: RecipientOptionObj[]) => {
    return arr.filter((t) => t.selected === isSelected)
  }
  const initialSuggested = getSelected(false, recipientOptions)

  const [suggested, setSuggested] =
    useState<RecipientOptionObj[]>(initialSuggested)

  const [selected, setSelected] = useState<RecipientOptionObj[]>([])
  useEffect(() => {
    const selected = getSelected(true, recipientOptions)
    setSelected(selected)
  }, [recipientOptions])

  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false)

  useEffect(() => {
    const toPatientId = selected.find((s) => !s.isProvider)?.apiId
    const toProviderIds = selected
      .filter((s) => s.isProvider)
      .map((s) => s.apiId)

    const attachmentArr = uploads.map((u: UploadObj) => {
      return {
        storageKey: u.key,
      }
    })

    let messageContentHTML
    if (editorState?.getCurrentContent()) {
      messageContentHTML = draftToHtml(
        convertToRaw(editorState?.getCurrentContent())
      )
    }

    const messageContentTxt = editorState
      .getCurrentContent()
      .getPlainText('\u0001')

    if (isNewMessage) {
      // send new message
      setSendMessageData({
        ...sendMessageData,
        toPatient: toPatientId,
        toProviders: toProviderIds,
        urgent: urgency,
        messageContent: messageContentHTML,
        attachments: attachmentArr,
      })
      if (messageContentTxt && (toPatientId || toProviderIds.length)) {
        setDisableSend(false)
      }
    } else {
      // send message in an existing thread
      setSendMessageData({
        ...sendMessageData,
        messageThreadId: threadId,
        urgent: urgency,
        messageContent: messageContentHTML,
        attachments: attachmentArr,
      })
      if (messageContentTxt) {
        setDisableSend(false)
      }
    }
  }, [recipientOptions, selected, suggested, urgency, editorState, uploads])

  // handle select an option
  const handleSelectRecipient = (recipient: RecipientOptionObj) => {
    if (!recipient.isUser) {
      return false
    }
    setSearchRecipientStr('')
    // as soons as 1 patient is selected, remove all patient options
    const theSelectedIndex = recipientOptions.findIndex(
      (r: RecipientOptionObj) =>
        r.apiId === recipient.apiId && r.isProvider === recipient.isProvider
    )
    recipientOptions[theSelectedIndex].selected = true

    setRecipientOptions([...recipientOptions])

    if (!recipient.isProvider) {
      const patientRemovedArr = recipientOptions.filter(
        (r: RecipientOptionObj) => r.isProvider
      )

      setSuggested([...patientRemovedArr])
    } else {
      const newSuggest = suggested.filter(
        (s: RecipientOptionObj) => !s.selected
      )
      setSuggested([...newSuggest])
    }
    setShowDropdown(false)
  }

  const handleDeleteRecipient = (recipient: RecipientOptionObj) => {
    if (!recipient.isProvider) {
      const theDeletedIndex = recipientOptions.findIndex(
        (r: RecipientOptionObj) =>
          // notice: a patient and a provider can have the same apiId
          r.apiId === recipient.apiId && !r.isProvider
      )
      recipientOptions[theDeletedIndex].selected = false
      setRecipientOptions([...recipientOptions])
      setSuggested([...getSelected(false, recipientOptions)])
    } else {
      const theDeletedIndex = recipientOptions.findIndex(
        (r: RecipientOptionObj) =>
          r.apiId === recipient.apiId && r.isProvider === recipient.isProvider
      )
      recipientOptions[theDeletedIndex].selected = false
      setRecipientOptions([...recipientOptions])

      setSuggested([...suggested, recipientOptions[theDeletedIndex]])
    }
  }

  const renderDropdown = () => {
    return (
      <div className={classes.msgRecipient__dropdown}>
        {suggested
          ?.filter(
            (s) =>
              s.isUser &&
              // NCA-76: Remove ability to add deactivated patient as a recipient to send a new message.
              // Tester Code
              // DONE
              !s.roles?.includes('Deactivated') // && // NCA-76: Remove ability with role changed.
            // s.name?.toLowerCase() !== 'taylor swift' && // Test removal of specified users
            // s.preferredName?.toLowerCase() !== 'taylor swift' // Test removel of specified users
          ) //remove non-users
          ?.filter(
            (s: RecipientOptionObj) =>
              s.name
                ?.toLowerCase()
                .includes(searchRecipientStr.toLowerCase()) ||
              s.preferredName
                ?.toLowerCase()
                .includes(searchRecipientStr.toLowerCase())
          )
          .map((r: RecipientOptionObj) => {
            const name = r.preferredName ?? r.name
            let initials = name[0] + name[name.lastIndexOf(' ') + 1]
            initials = initials.toUpperCase()

            return (
              <button
                className={classes.msgRecipient_tag}
                key={`option-${name}-${r.apiId}`}
                onClick={() => handleSelectRecipient(r)}
                disabled={!r.isUser}
              >
                <div className={classes.msgRecipient_tag_left}>
                  <div
                    className={classes.msgRecipient_tag_avatar}
                    style={{
                      backgroundImage: r.photoUrl
                        ? `url(${r.photoUrl})`
                        : 'none',
                    }}
                  >
                    {r.photoUrl ? null : initials}
                  </div>
                </div>

                <div className={classes.msgRecipient_tag_right}>
                  <div>{name}</div>
                  <div className={classes.msgRecipient_tag_subtext}>
                    {r.mrn}
                  </div>
                </div>
              </button>
            )
          })}
      </div>
    )
  }

  const renderSelected = () => {
    return selected.map((s) => {
      const uniqueId = s.isProvider
        ? `provider-${s.apiId}`
        : `patient-${s.apiId}`

      return (
        <Box
          key={`selected-${s?.label}-${s?.apiId}`}
          style={{ display: 'inline-block' }}
        >
          <Chip
            className={classes.msgFrom_To_chip}
            label={s.label}
            onDelete={() => handleDeleteRecipient(s)}
            aria-describedby={uniqueId}
            onClick={async (e) => {
              setAnchor(e.currentTarget)
              s.isProvider && (await getProviderContactInfo(s.apiId))
              setClickedPopover({
                isProvider: s.isProvider,
                username: s.isProvider && s.name ? s?.name : s?.preferredName,
                photoUrl: s?.photoUrl,
                patientMrn: !s.isProvider ? s.mrn : '',
                providerRole: s.isProvider && s.roles ? s?.roles[0] : '',
                providerOrPatientId: s.apiId,
                uniqueId: uniqueId,
              })
            }}
          />
        </Box>
      )
    })
  }

  const renderChipPopover = () => {
    return (
      <PatientOrProviderDetails
        id='recipient-popover'
        open={!!anchor}
        handleClose={() => setAnchor(null)}
        anchorEl={anchor}
        aria-describedby='recipient-popover'
        type={
          clickedPopover?.isProvider
            ? PatientOrProvider.Provider
            : PatientOrProvider.Patient
        }
        user={{
          name: clickedPopover?.username,
          photoUrl: clickedPopover?.photoUrl,
          patientMrn: clickedPopover?.patientMrn,
          providerRole: clickedPopover?.providerRole,
          patientOrProvider: clickedPopover?.isProvider
            ? PatientOrProvider.Provider
            : PatientOrProvider.Patient,
        }}
        providerOrPatientId={clickedPopover?.providerOrPatientId}
        contact={providerContactInfo.email ? providerContactInfo : undefined}
      />
    )
  }

  const renderRecipients = () => {
    //  for an existing thread
    if (thread) {
      return (
        <>
          <div className={classes.msgCreate__userList}>
            {thread?.otherUsers?.map((u) => {
              return (
                <Chip
                  key={`${u.name}-${u.role}`}
                  label={u.name}
                  className={classes.msgFrom_To_chip}
                  onClick={async (e) => {
                    setAnchor(e.currentTarget)
                    !u.isPatient && (await getProviderContactInfo(u.id))
                    setClickedPopover({
                      isProvider: !u.isPatient,
                      username: u.name,
                      photoUrl: u?.photoUrl ? u?.photoUrl : '',
                      patientMrn: u.role,
                      providerRole: u.role,
                      providerOrPatientId: u.id,
                      uniqueId: `${u.isPatient ? 'patient' : 'provider'}-${
                        u.id
                      }`,
                    })
                  }}
                />
              )
            })}
          </div>
          {renderChipPopover()}
        </>
      )
    } else {
      // for new message
      return (
        <div className={classes.msgCreate__userList}>
          <div className={classes.msgRecipient_toLine}>
            {renderSelected()}
            {renderChipPopover()}
            <textarea
              value={searchRecipientStr}
              className={classes.msgRecipient_toLine_textarea}
              onChange={(e) => setSearchRecipientStr(e.target.value)}
              onFocus={() => recipientOptions.length && setShowDropdown(true)}
              placeholder='Type name/MRN'
            />
          </div>

          {showDropdown ? renderDropdown() : null}
        </div>
      )
    }
  }

  //  handle attach button
  const uploadChangeHandler = async (event: any) => {
    const [uploadedFile] = event.target.files

    let src = ''
    if (uploadedFile) {
      src = URL.createObjectURL(uploadedFile)
    }

    try {
      const photo = await fetch(src)
      const photoBlob = await photo.blob()
      const photoStorage = await Storage.put(
        `message-attachments/${Date.now()}/${src?.slice(-4)}.${
          uploadedFile.type?.split('/')[1]
        }`,
        photoBlob
      )

      const uploadObj = {
        index: src?.slice(-8),
        type: uploadedFile.type,
        name: uploadedFile.name,
        src: src,
        key: photoStorage.key,
      }

      uploadObj && setUploads([...uploads, uploadObj])
      setUploadInProgress(false)
    } catch (err) {
      console.error(err)
    }
  }

  const clearStates = () => {
    setAttachmentKeys && setAttachmentKeys([])
    setUploads([])
    setRecipientOptions([...initialRecipientOptions])
    setSuggested(getSelected(true, recipientOptions))
    setSendMessageData({
      toProviders: [],
      toPatient: 0,
      urgent: false,
      messageContent: '',
      attachments: [],
    })
    setEditorState(EditorState.createEmpty())
  }

  // NCA-76: Add banner in message thread “Inactive Banner” if thread contains deactivated patient.
  const renderInactiveNotice = () => {
    if (
      !isNewMessage &&
      // NCA-76 TODO: Replace with code to pull deactivated status
      thread?.otherUsers?.some((u) => {
        // console.log('Role:', u.role)
        return u.role === 'Deactivated'
      }) // u.name?.toLowerCase() === 'taylor swift')
    ) {
      return (
        <Box
          sx={{
            backgroundColor: themeColors.blue40,
            color: themeColors.white,
            position: 'sticky',
            height: 50,
            fontWeight: 'bold',
            width: '100%',
            padding: '10px 15px',
            display: 'flex', // Center text horizontally and vertically
            justifyContent: 'center', // Center text horizontally
            alignItems: 'center', // Center text vertically
            borderRadius: '20px', // Rounded corners
          }}
        >
          NOTICE: Patient is not active and cannot recieve messages.
        </Box>
      )
    }
  }

  const renderActionBtns = () => {
    const btnStyle = {
      border: 'none',
      backgroundColor: 'transparent',
      marginLeft: 25,
      cursor: 'pointer',
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 5,
        }}
      >
        <button
          type='button'
          style={btnStyle}
          disabled={disableSend}
          onClick={async () => {
            setDisableSend(true)
            await sendMessage(sendMessageData)
            threadId && getProviderMessagesInThread(threadId, 0)
            clearStates()
            setIsNewMessage && setIsNewMessage(false)
            setDisableSend(false)
          }}
        >
          {disableSend ? (
            <CircularProgress size={20} color='primary' />
          ) : (
            <img src={SendSvg} alt='send message' />
          )}
        </button>
        <button
          type='button'
          style={btnStyle}
          onClick={() => {
            clearStates()
          }}
          className={classes.msgThread__actions__btn}
          disabled={disableSend}
        >
          <img src={TrashSvg} alt='discard message' />
        </button>
      </div>
    )
  }

  const renderEditor = () => {
    return (
      <div className={classes.msgReply_box_wrapper}>
        {/* only show the from/to section in editing newMessage */}
        {isNewMessage && (
          <div className={classes.msgCreate__userRow}>
            <div className={classes.msgCreate__label}>From:</div>
            <div className={classes.msgCreate__userList}>
              <Chip
                label={`${myProviderDetails?.preferredFirstName} ${myProviderDetails?.lastName}`}
                className={classes.msgFrom_To_chip}
                onClick={async (e: {
                  currentTarget: SetStateAction<HTMLElement | null>
                }) => {
                  setAnchor(e.currentTarget)
                  myProviderDetails?.providerId &&
                    getProviderContactInfo(myProviderDetails?.providerId)
                  myProviderDetails?.preferredFirstName &&
                    myProviderDetails?.photoUrl &&
                    myProviderDetails?.role &&
                    myProviderDetails?.providerId &&
                    setClickedPopover({
                      isProvider: true,
                      username: myProviderDetails?.preferredFirstName,
                      photoUrl: myProviderDetails?.photoUrl,
                      patientMrn: '',
                      providerRole: myProviderDetails?.role,
                      providerOrPatientId: myProviderDetails?.providerId,
                      uniqueId: `provider-${myProviderDetails?.providerId}`,
                    })
                }}
              />
            </div>
            <div className={classes.msgCreate__userRow__actions}>
              <IconButton
                className={classes.msgCreate__userRow__priorityBtn}
                color='primary'
                component='span'
                size='small'
                onClick={() => {
                  setUrgency(!urgency)
                }}
              >
                <FlagSvg
                  fill={urgency ? themeColors.red : themeColors.gray50}
                />
              </IconButton>
            </div>
          </div>
        )}

        {isNewMessage && (
          <div className={classes.msgCreate__userRow}>
            <div className={classes.msgCreate__label}>To:</div>
            {renderRecipients()}
          </div>
        )}

        <Editor
          editorState={editorState}
          onEditorStateChange={(e: any) => {
            setEditorState && setEditorState(e)
          }}
          onFocus={() => {
            if (showDropdown) {
              setSearchRecipientStr('')
              setShowDropdown(false)
            }
          }}
          ref={editorRef}
          toolbar={{
            options:
              isStandAloneView || isMobile
                ? []
                : [
                    'inline',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'emoji',
                    'link',
                  ],
            list: isMobile ? {} : { options: ['unordered', 'ordered'] },
            inline: isMobile
              ? {}
              : {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
            textAlign: { inDropdown: true },
            link: { options: ['link'] },
            emoji: {
              emojis: [
                '😀',
                '😁',
                '😂',
                '😃',
                '😉',
                '😋',
                '😎',
                '😍',
                '😗',
                '🤗',
                '🤔',
                '😣',
                '😫',
                '😴',
                '😌',
                '🤓',
                '😛',
                '😜',
                '😠',
                '😇',
                '😷',
                '😈',
                '👻',
                '😺',
                '😸',
                '😹',
                '😻',
                '😼',
                '😽',
                '🙀',
                '🙈',
                '🙉',
                '🙊',
                '👼',
                '👮',
                '🕵',
                '💂',
                '👳',
                '🎅',
                '👸',
                '👰',
                '👲',
                '🙍',
                '🙇',
                '🚶',
                '🏃',
                '💃',
                '⛷',
                '🏂',
                '🏌',
                '🏄',
                '🚣',
                '🏊',
                '⛹',
                '🏋',
                '🚴',
                '👫',
                '💪',
                '👈',
                '👉',
                '👉',
                '👆',
                '🖕',
                '👇',
                '🖖',
                '🤘',
                '🖐',
                '👌',
                '👍',
                '👎',
                '✊',
                '👊',
                '👏',
                '🙌',
                '🙏',
                '🐵',
                '🐶',
                '🐇',
                '🐥',
                '🐸',
                '🐌',
                '🐛',
                '🐜',
                '🐝',
                '🍉',
                '🍄',
                '🍔',
                '🍤',
                '🍨',
                '🍪',
                '🎂',
                '🍰',
                '🌍',
                '🚑',
                '⏰',
                '🌙',
                '🌝',
                '🌞',
                '⭐',
                '🌟',
                '🌠',
                '🌨',
                '🌩',
                '⛄',
                '🔥',
                '🎄',
                '🎈',
                '🎉',
                '🎊',
                '🎁',
                '🎗',
                '🏀',
                '🏈',
                '🎲',
                '🔇',
                '🔈',
                '📣',
                '🔔',
                '🎵',
                '🎷',
                '💰',
                '🖊',
                '📅',
                '✅',
                '❎',
                '💯',
              ],
            },
          }}
          wrapperStyle={{
            padding: 0,
            margin: 0,
            maxWidth: '100%',
          }}
          editorStyle={{
            margin: 0,
            paddingRight: 15,
            minHeight: isMobile ? '20px' : '5rem',
            height: 'max-content',
            width: '100%',
          }}
          toolbarStyle={{
            border: 'none',
            margin: 0,
            padding: 0,
            backgroundColor: 'transparent',
            width: '100%',
            flexWrap: 'wrap',
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <input
              style={{ display: 'none' }}
              id='attachFile'
              type='file'
              onChange={async (e) => {
                setUploadInProgress(true)
                await uploadChangeHandler(e)
                setUploadInProgress(false)
              }}
            />
            <label
              htmlFor='attachFile'
              style={{
                color: themeColors.blue,
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img src={AttachmentSvg} alt='attach file' />
              <Typography
                marginRight={2}
                color={themeColors.blue}
                fontSize={12}
              >
                Add Attachment
              </Typography>{' '}
            </label>
          </div>

          {renderActionBtns()}
        </div>

        {/* attachment preview */}
        <div className={classes.msgAttachments_preview_wrapper}>
          {uploadInProgress && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Typography
                marginRight={2}
                color={themeColors.gray50}
                fontSize={12}
              >
                Uploading...
              </Typography>{' '}
              <CircularProgress
                size={18}
                style={{ marginRight: 10, color: themeColors.gray50 }}
              />
            </div>
          )}
          {uploads.length > 0 &&
            uploads.map((u: UploadObj, i: number) => {
              if (u.src) {
                return (
                  <div
                    key={i}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginRight: 20,
                    }}
                  >
                    <img
                      id='attachFile'
                      src={u.type.includes('image') ? u.src : FileSvg}
                      alt='uploaded file'
                      style={{
                        objectFit: 'contain',
                        height: '100px',
                        maxWidth: u.type.includes('image') ? '200px' : '100px',
                        borderRadius: '.5rem',
                      }}
                      key={`img-${i}`}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: 200,
                      }}
                    >
                      <button
                        type='button'
                        onClick={() => {
                          const toBeRemoved = uploads.findIndex(
                            (obj: UploadObj) => obj.index === u.index
                          )
                          if (toBeRemoved !== -1) {
                            uploads.splice(toBeRemoved, 1)
                            setUploads([...uploads])
                          }
                        }}
                        style={{
                          fontSize: 14,
                          border: 'none',
                          cursor: 'pointer',
                        }}
                        className={classes.msgThread__attachment}
                      >
                        <span style={{ fontWeight: 'bolder', marginRight: 5 }}>
                          {'\u00D7'}
                        </span>
                        <span>{u.name && u.name.slice(-10)}</span>
                      </button>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })}
        </div>
      </div>
    )
  }

  // editor box
  return (
    // NCA-76: Add banner in message thread “Inactive Banner” if thread contains deactivated patient.
    <Grid sx={{ marginTop: '20px' }}>
      <div>{renderInactiveNotice()}</div>
      <div>{renderEditor()}</div>
    </Grid>
  )
}

export default MessageEditor
