export enum ViewType {
  List,
  Details,
  Add,
  Edit,
}

// NCA-43 PatientTableRow
export type PatientTableRow = {
  patientId: number
  preferredName: string
  photoUrl: string | null
  mrn: string
  isActive: boolean
  deactivationDate?: Date
  accessRoles: string
}
