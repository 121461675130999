/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Grid, Link } from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useMessages } from '../../../../providers/Messages/Messages.provider'
import {
  MessageThreadData,
  UploadObj,
} from '../../../../providers/Messages/types'
import { useProviderDetails } from '../../../../providers/ProviderDetails/ProviderDetails.provider'
import { themeColors } from '../../../../theme'
import useInterval from '../../../../utilities/useIntervals'
import Message from './Message.view'
import MessageEditor from './MessageEditor.view'
import MessageThread from './MessageThread.view'
import { useStyles } from './Messaging.styles'

type MessageTabConfig = {
  name: string
  hideOnMobile?: boolean
}

interface MessageTabs {
  myPatients: MessageTabConfig
  providers: MessageTabConfig
  otherPatients: MessageTabConfig
  // NCA-47 Created new Message Tab
  pastPatients: MessageTabConfig
}

const tabs: MessageTabs = {
  myPatients: {
    name: 'My Patients',
  },
  providers: {
    name: 'Providers',
  },
  otherPatients: {
    name: 'All Patients',
    hideOnMobile: true,
  },
  // NCA-47 Created new Message Tab
  pastPatients: { name: 'Past Patients', hideOnMobile: true },
}

interface MessagingProps {
  isStandAloneView?: boolean
}

const Messaging = ({ isStandAloneView }: MessagingProps) => {
  const { classes } = useStyles()
  const [activeTab, setActiveTab] = useState<keyof MessageTabs>('myPatients')
  const [threadId, setThreadId] = useState<number | null>(null)
  const { routedThreadId } = useParams()
  const navigate = useNavigate()

  const {
    threads,
    getProviderMessageThreads,
    thread,
    getProviderMessagesInThread,
    attachmentKeys,
    setAttachmentKeys,
    isNewMessage,
    setIsNewMessage,
  } = useMessages()
  const { myProviderDetails, getMyProviderDetails } = useProviderDetails()

  useEffect(() => {
    if (routedThreadId) {
      if (!Number.isNaN(~~routedThreadId)) {
        setThreadId(Number(routedThreadId))
      }
    }
  }, [routedThreadId])

  useEffect(() => {
    getProviderMessageThreads()
  }, [])

  // check if getting any messages in thread; if not, provider(user) may not in the thread; push to home
  const messageThreadCheck = async () => {
    if (threadId) {
      try {
        const data = await getProviderMessagesInThread(threadId, 0)
        console.log('data', data)
        if (!data.messages.length) {
          navigate('/')
          console.log('navigating')
        }
      } catch (e) {
        console.log('messageThreadCheck-error', e)
      }
    }
  }

  useEffect(() => {
    messageThreadCheck()
    getProviderMessageThreads()
  }, [threadId])

  useInterval(() => {
    getProviderMessageThreads()
    getMyProviderDetails()
  }, 10000)

  const goToThread = (thread: MessageThreadData) => {
    setThreadId(thread.messageThreadId)
  }

  const backToMessages = () => {
    setThreadId(null)
    setIsNewMessage(false)
  }

  const renderTabs = (isStandAloneView?: boolean) => {
    return (
      <div className={classes.messaging__tabs}>
        {Object.entries(tabs).map(([key, val]: [string, MessageTabConfig]) => {
          return !(isStandAloneView && val.hideOnMobile) ? (
            <button
              key={`tab${key}`}
              className={classes.messaging__tab}
              onClick={() => {
                setActiveTab(key as keyof MessageTabs)
                setThreadId(null)
              }}
              style={{ fontWeight: activeTab === key ? 'bold' : '' }}
            >
              {tabs[key as keyof MessageTabs].name}
              {`(${
                threads[key as keyof MessageTabs]?.filter((m) => m.unread)
                  .length
              })`}
              {activeTab === key ? (
                <div className={classes.messaging__tab__active}></div>
              ) : null}
            </button>
          ) : null
        })}
      </div>
    )
  }

  // threads list
  const renderMessageThreadPreviews = () => {
    if (threadId) {
      return null
    }
    // activeTab
    switch (activeTab) {
      case 'myPatients':
        return threads.myPatients.map((thread, idx) => (
          <Grid item width={'100%'} key={idx}>
            <Message
              myProviderDetails={myProviderDetails}
              isStandAloneView={isStandAloneView}
              message={thread.messages[0]}
              keyValue={`mp${idx}`}
              unread={thread.unread}
              openThread={() => goToThread(thread)}
              displayUser={thread.displayUser}
              displayThreadUserNames={thread.displayThreadUserNames}
              displayUserCount={thread.displayUserCount}
            />
          </Grid>
        ))
      case 'providers':
        return threads.providers.map((thread, idx) => (
          <Grid item width='100%' key={idx}>
            <Message
              myProviderDetails={myProviderDetails}
              isStandAloneView={isStandAloneView}
              message={thread.messages[0]}
              keyValue={`mp${idx}`}
              unread={thread.unread}
              openThread={() => goToThread(thread)}
              displayUser={thread.displayUser}
              displayThreadUserNames={thread.displayThreadUserNames}
              displayUserCount={thread.displayUserCount}
            />
          </Grid>
        ))
      // NCA-47 Create new Message Tab, not implemented yet
      case 'pastPatients':
        // return threads.pastPatients.map((thread, idx) => (
        return threads.pastPatients.map((thread, idx) => (
          <Grid item width='100%' key={idx}>
            <Message
              myProviderDetails={myProviderDetails}
              isStandAloneView={isStandAloneView}
              message={thread.messages[0]}
              keyValue={`mp${idx}`}
              unread={thread.unread}
              openThread={() => goToThread(thread)}
              displayUser={thread.displayUser}
              displayThreadUserNames={thread.displayThreadUserNames}
              displayUserCount={thread.displayUserCount}
            />
          </Grid>
        ))
      default:
        return threads.otherPatients.map((thread, idx) => (
          <Grid item width='100%' key={idx}>
            <Message
              myProviderDetails={myProviderDetails}
              isStandAloneView={isStandAloneView}
              message={thread.messages[0]}
              keyValue={`mp${idx}`}
              unread={thread.unread}
              openThread={() => goToThread(thread)}
              displayUser={thread.displayUser}
              displayThreadUserNames={thread.displayThreadUserNames}
              displayUserCount={thread.displayUserCount}
            />
          </Grid>
        ))
    }
  }

  const [uploads, setUploads] = useState<UploadObj[]>([])

  // thread detail
  const renderMessageThread = () => {
    if (!threadId) {
      return null
    }
    return (
      <MessageThread
        myProviderDetails={myProviderDetails!}
        isStandAloneView={isStandAloneView}
        backToMessages={backToMessages}
        messageThread={thread}
        threadId={threadId}
        attachmentKeys={attachmentKeys}
        setAttachmentKeys={setAttachmentKeys}
        uploads={uploads}
        setUploads={setUploads}
      />
    )
  }

  const renderBackBtn = () => {
    return (
      <button
        type='button'
        onClick={backToMessages}
        className={classes.messageThread_backBtn}
      >
        &#8592;
      </button>
    )
  }

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      <div>
        {myProviderDetails
          ? !myProviderDetails?.companyPhone && (
              <Alert
                color='error'
                variant='filled'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '15px',
                }}
              >
                Add Company Phone in your
                <Link
                  component='button'
                  underline='none'
                  color={(theme) => theme.palette.info.main}
                  onClick={() => navigate('/account')}
                  sx={{
                    padding: '2px 7px',
                    margin: '-2px 5px',
                    borderRadius: '10px',
                    background: `rgba(255, 255, 255, 0.6)`,
                    color: themeColors.blue40,
                    fontWeight: 'bold',
                  }}
                >
                  Account Settings
                </Link>
                to Receive notifications.
              </Alert>
            )
          : null}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        {!threadId && renderTabs(isStandAloneView)}
      </div>
      {isNewMessage ? (
        // render newMessage editing box
        <div style={{ padding: '0 14px', height: '100%', overflow: 'auto' }}>
          {renderBackBtn()}
          <MessageEditor
            myProviderDetails={myProviderDetails}
            isStandAloneView={isStandAloneView}
            backToMessages={backToMessages}
            uploads={uploads}
            setUploads={setUploads}
            setAttachmentKeys={setAttachmentKeys}
            setIsNewMessage={setIsNewMessage}
            isNewMessage={isNewMessage}
          />
        </div>
      ) : (
        <div
          className={classes.messaging__list}
          style={{
            height: '80%',
            overflow: 'auto',
          }}
        >
          <Grid container spacing={2}>
            {renderMessageThreadPreviews()}
          </Grid>
          {renderMessageThread()}
        </div>
      )}
    </div>
  )
}

export default Messaging
