import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useEffect, useState } from 'react'

import { Modal } from '../../../../components'
import { Patient } from '../../../../providers/Patients/types'
import {
  AccessRole,
  AccessRoleList,
  Provider,
} from '../../../../providers/ProvidersProvider/types'
import { themeColors } from '../../../../theme'

// Create interface for EditProviderModalProps
interface EditPatientModalProps {
  selectedPatient: Patient | null
  editPatientModal: boolean
  setEditPatientModal: React.Dispatch<React.SetStateAction<boolean>>
  patientAccessRoles: AccessRoleList | null
  handleEditPatientAccess: (
    selectedPatient: Patient,
    accessRoleId: number
  ) => Promise<void>
  setIsEditLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditPatientModal = ({
  selectedPatient,
  editPatientModal,
  setEditPatientModal,
  patientAccessRoles,
  handleEditPatientAccess,
  setIsEditLoading,
}: EditPatientModalProps) => {
  const [accessRole, setAccessRole] = useState<{
    id: number
    description: string
  }>()

  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  // NCA-34, default date selected is the next day from today
  useEffect(() => {
    if (!selectedDate) {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(today.getDate() + 1)
      setSelectedDate(tomorrow)
    }
  }, [selectedDate])

  const handleChange = (event: SelectChangeEvent) => {
    const role = patientAccessRoles?.find(
      (r) => r.id === parseInt(event.target.value)
    )
    setAccessRole(role)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal
        open={editPatientModal}
        setOpen={setEditPatientModal}
        title={'Edit Patient'}
        width={'30%'}
        ModalContent={
          <Box sx={{ width: '92%', margin: 'auto' }}>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '19px',
                color: themeColors.grayblack,
              }}
            >
              Are you sure you want to change the access role of patient:{' '}
              {selectedPatient?.preferredName}?
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: themeColors.gray20,
                marginTop: '10px',
              }}
            >
              REQUIRED: Please select a new access role.
            </Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel
                id='demo-select-small-label'
                style={{ fontSize: '15px' }}
              >
                Access Role
              </InputLabel>
              <Select
                labelId='demo-select-small-label'
                id='demo-select-small'
                value={String(accessRole?.id)}
                label='Access Role'
                onChange={handleChange}
              >
                {/* <MenuItem value=''>
                  <em>None</em>
                </MenuItem> */}
                {patientAccessRoles?.map((a: AccessRole) => (
                  <MenuItem
                    value={a.id}
                    key={`access-role-${a.id}--${a.description}`}
                  >
                    {a.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {accessRole?.description === 'Deactivated' ? (
              <Box sx={{ width: '92%', margin: 'auto' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '19px',
                    color: themeColors.grayblack,
                  }}
                >
                  Are you sure you want to deactivate{' '}
                  {selectedPatient?.preferredName} from the program?
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: themeColors.gray20,
                    marginTop: '10px',
                  }}
                >
                  REQUIRED: Please select a deactivation date.
                </Typography>
                <DatePicker
                  // Update date for it to be tomo
                  value={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size='small'
                      variant='outlined'
                      sx={{ height: '60px' }}
                    />
                  )}
                />

                <Box
                  sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}
                >
                  <Button
                    variant='contained'
                    onClick={() => setEditPatientModal(false)}
                    sx={{ marginRight: '10px' }}
                  >
                    No
                  </Button>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: themeColors.gray20,
                    }}
                  >
                    {/* //NCA-34 changed to fit better phrasing */}
                    {/* Close window and return to patient view. */}
                    Do not deactivate patient.
                  </Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}
                >
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={async () => {
                      setEditPatientModal(false)
                      if (selectedPatient) {
                        await handleEditPatientAccess(
                          selectedPatient,
                          accessRole!.id
                        )
                      }
                    }}
                    sx={{ marginRight: '10px' }}
                  >
                    Yes
                  </Button>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: themeColors.gray20,
                    }}
                  >
                    Deactivate <strong>{selectedPatient?.preferredName}</strong>{' '}
                    from the program.
                  </Typography>
                </Box>
              </Box>
            ) : (
              <>
                <Box
                  sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}
                >
                  <Button
                    variant='contained'
                    onClick={() => setEditPatientModal(false)}
                    sx={{ marginRight: '10px' }}
                  >
                    No
                  </Button>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: themeColors.gray20,
                    }}
                  >
                    {/* //NCA-34 changed to fit better phrasing */}
                    {/* Close window and return to patient view. */}
                    Do not change the access role patient.
                  </Typography>
                </Box>
                <Box
                  sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}
                >
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={async () => {
                      setEditPatientModal(false)
                      if (selectedPatient) {
                        setIsEditLoading(true)
                        await handleEditPatientAccess(
                          selectedPatient,
                          accessRole!.id
                        )
                        setIsEditLoading(false)
                      }
                    }}
                    sx={{ marginRight: '10px' }}
                  >
                    Yes
                  </Button>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: themeColors.gray20,
                    }}
                  >
                    Change the access role of patient{' '}
                    <strong>{selectedPatient?.preferredName}</strong>.
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      lineHeight: '12px',
                      color: themeColors.gray20,
                      marginTop: '8px',
                    }}
                  >
                    <strong>Note: </strong> If patient is currently deactivated
                    or pending deactivation, they will be reactivated.
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        }
      />
    </LocalizationProvider>
  )
}
