import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { useState } from 'react'

import { Modal } from '../../../../components'
import {
  AccessRole,
  AccessRoleList,
  Provider,
} from '../../../../providers/ProvidersProvider/types'
import { themeColors } from '../../../../theme'

// Create interface for EditProviderModalProps
interface EditProviderModalProps {
  selectedProvider: Provider | null
  editProviderModal: boolean
  setEditProviderModal: React.Dispatch<React.SetStateAction<boolean>>
  providerAccessRoles: AccessRoleList | null
  handleEditProviderAccess: (
    selectedProvider: Provider,
    accessRoleId: number
  ) => Promise<void>
  setIsEditLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditProviderModal = ({
  selectedProvider,
  editProviderModal,
  setEditProviderModal,
  providerAccessRoles,
  handleEditProviderAccess,
  setIsEditLoading,
}: EditProviderModalProps) => {
  const [accessRole, setAccessRole] = useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setAccessRole(event.target.value)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Modal
        open={editProviderModal}
        setOpen={setEditProviderModal}
        title={'Edit Provider'}
        width={'30%'}
        ModalContent={
          <Box sx={{ width: '92%', margin: 'auto' }}>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '19px',
                color: themeColors.grayblack,
              }}
            >
              Are you sure you want to change the access role of provider:{' '}
              {selectedProvider?.name}?
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: themeColors.gray20,
                marginTop: '10px',
              }}
            >
              REQUIRED: Please select a new access role.
            </Typography>

            <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
              <InputLabel
                id='demo-select-small-label'
                style={{ fontSize: '15px' }}
              >
                Access Role
              </InputLabel>
              <Select
                labelId='demo-select-small-label'
                id='demo-select-small'
                value={accessRole}
                label='Access Role'
                onChange={handleChange}
              >
                <MenuItem value='' disabled>
                  {''}
                </MenuItem>
                {providerAccessRoles?.map((a: AccessRole) => (
                  <MenuItem value={a.id} key={`access-role-${a.id}`}>
                    {a.description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ display: 'flex', margin: '7px', alignItems: 'center' }}>
              <Button
                variant='outlined'
                color='error'
                onClick={async () => {
                  setEditProviderModal(false)
                  if (selectedProvider) {
                    setIsEditLoading(true)

                    await handleEditProviderAccess(
                      selectedProvider,
                      parseInt(accessRole)
                    )
                    setIsEditLoading(false)
                  }
                }}
                sx={{ marginRight: '10px' }}
              >
                Yes
              </Button>
              <Typography
                sx={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: themeColors.gray20,
                }}
              >
                Change the access role of provider{' '}
                <strong>{selectedProvider?.name}</strong>.
              </Typography>
            </Box>
          </Box>
        }
      />
    </LocalizationProvider>
  )
}
