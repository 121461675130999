import { startCase, toLower } from 'lodash'

import { PeerGroupListItem } from '../../../providers/PeerGroups/types'
import { dateFormatters } from '../../../utilities/HelperFunctions'
import { PeerGroupTableRow } from './types'

export const formatPeerGroupForTable = (
  apiPeerGroupListItem: PeerGroupListItem
): PeerGroupTableRow => ({
  ...apiPeerGroupListItem,
  peerGroupId: apiPeerGroupListItem.peerGroupId,
  moderators: apiPeerGroupListItem.moderators.join(', '),
  dayOfWeek: dateFormatters.dayOfWeek(apiPeerGroupListItem.startTime),
  startTime: dateFormatters.meetingTimes(
    apiPeerGroupListItem.startTime,
    apiPeerGroupListItem.endTime
  ),
  endDate: dateFormatters.prettyDateWithTime(apiPeerGroupListItem.endDate!),
  frequency: startCase(toLower(apiPeerGroupListItem.frequency)),
  createdOnDate: dateFormatters.prettyDateWithTime(
    apiPeerGroupListItem.createdOnDate
  ),
})
