import { Box, CircularProgress } from '@mui/material'
import { useState } from 'react'

import { useOptions } from '../../../providers/Options/Options.provider'
import { useProviders } from '../../../providers/ProvidersProvider/ProvidersProvider.provider'
import { Provider } from '../../../providers/ProvidersProvider/types'
import { ProvidersView } from './Providers.view'
import { ViewType } from './types'

const Providers = () => {
  const { providers, providerAccessRoles, updateProviderAccessRole } =
    useProviders()
  const { optionsLoading } = useOptions()

  const [viewMode, setViewMode] = useState<ViewType>(ViewType.List)

  const [editProviderModal, setEditProviderModal] = useState<boolean>(false)

  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(
    null
  )

  const handleEditProviderModal = (selectedProviderID: number) => {
    const selectedProvider = providers?.find(
      (p) => p.providerId === selectedProviderID
    )
    if (selectedProvider) {
      setSelectedProvider(selectedProvider)
      setEditProviderModal(true)
    }
  }

  const handleEditProviderAccess = async (
    selectedProvider: Provider,
    accessRoleId: number
  ): Promise<void> => {
    await updateProviderAccessRole(selectedProvider.providerId, accessRoleId)
  }

  return providers && providers.length > 0 && !optionsLoading ? (
    <ProvidersView
      viewMode={viewMode}
      providers={providers}
      providerAccessRoles={providerAccessRoles}
      selectedProvider={selectedProvider}
      editProviderModal={editProviderModal}
      setEditProviderModal={setEditProviderModal}
      handleEditProviderModal={handleEditProviderModal}
      handleEditProviderAccess={handleEditProviderAccess}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <CircularProgress />{' '}
    </Box>
  )
}

export { Providers }
